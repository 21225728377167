import React, { useRef, useState, useContext, useEffect } from 'react';

import { useHistory } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { AuthContext } from "../../components/Auth/AuthDataProvider";
import authApi from '../../api/Authentication';
import apiUtil from '../../api/apiUtil';

import logo from '../../assets/img/logo_text_white.png';

export default function SignIn() {
  let history = useHistory();

  const { setAuthenticated, setToken, setAccessToken, setRefreshToken, setTokenType, setScope, setUser, setCustomer } = useContext(AuthContext);

  const [validatedEmail, setValidatedEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState(null);
  const [validatedPass, setValidatedPass] = useState(false);
  const [errorPass, setErrorPass] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customerLoginChecked, setChecked] = useState(false);

  const isMounted = useRef(null);
  const emailInput = useRef();
  const pswInput = useRef();
  const axiosRef = useRef();

  useEffect(() => {
    // executed when component mounted
    axiosRef.current = apiUtil.getCancelToken();
    isMounted.current = true;
    document.title = "Login | WMS";

    return () => {
      // executed when unmount
      isMounted.current = false;
      axiosRef.current.cancel();
    }
  }, []);


  const handleCustomerLoginCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    setError(null);

    setValidatedEmail(false);
    setErrorEmail(null);
    setValidatedPass(false);
    setErrorPass(null);

    if (emailInput.current.lastChild.firstChild.value === '') {
      setValidatedEmail(true);
      setErrorEmail('Please enter your email address.');
    }

    if (pswInput.current.lastChild.firstChild.value === '') {
      setValidatedPass(true);
      setErrorPass('Please enter your password.');
    }
    if (form.checkValidity() === true) {
      setIsLoading(true);
      authApi.login(emailInput.current.lastChild.firstChild.value, pswInput.current.lastChild.firstChild.value, axiosRef.current.token, customerLoginChecked)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            setAuthenticated(true);
            setAccessToken(data.token.access_token);
            setRefreshToken(data.token.refresh_token);
            setTokenType(data.token.token_type);
            setScope(data.token.scope);
            console.log('scope login: ' + data.token.scope);
            setToken(JSON.stringify(data.token));
            setUser(JSON.stringify(data));
            if (data.owner_info.customers) {
              setCustomer(JSON.stringify(data.owner_info.customers[0]));
            }

            let scope = data.token.scope;

            if (scope == 'worker') {
              history.push("/transaction/picking-order");
            } else if (scope == 'customer_admin') {
              history.push("/transaction/sales-order");
            } else {
              history.push("/customers");
            }
          }, (error, type) => {
            setError({ title: error });
            setIsLoading(false);
          });
        });
    }
  };

  return (
    <div className="signin">
      <div className="cover">
        <div className="cover__text">Equipment you can count on. People you can trust</div>
      </div>
      <div className="login" component="main" maxwidth="sm">
        <div className="top-menu">
          <div className="logo"><img src={logo} alt="" /></div>
        </div>
        {
          error && !isLoading
          &&
          <Box textAlign="left" mt={3} mb={3}>
            <Alert severity="error">
              {error.title && <b>{error.title}</b>}
              {(error.errors && error.errors.length > 0) && error.errors.map((e, i) => {
                return <div key={i}>{e}</div>
              })}
            </Alert>
          </Box>


        }
        <div className="form-wrapper">
          <form noValidate onSubmit={handleSubmit}>

            <Box textAlign="center" mb={3}>
              <div className="form-title">
                Welcome back!
              </div>
              <div className="form-description">
                Login into your dash express dashboard with your credentials
              </div>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                defaultValue=""
                error={validatedEmail}
                helperText={errorEmail}
                ref={emailInput}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                defaultValue=""
                autoComplete="current-password"
                error={validatedPass}
                helperText={errorPass}
                ref={pswInput}
              />
            </Box>
            <Box mb={1}>
              <Grid container>
                <Grid item xs>
                  <FormControlLabel
                    control={
                      <Checkbox name="customerLogin"
                        id="customerLogin"
                        onChange={handleCustomerLoginCheck} />
                    }
                    label="Customer Login" />
                </Grid>
                <Grid item>
                </Grid>
              </Grid>
            </Box>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isLoading}
            >
              <Box pl={2} pr={2} style={{ color: 'white' }}>SIGN IN</Box>
              {isLoading && <CircularProgress size={15} style={{ color: 'white' }} />}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}