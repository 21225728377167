import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PickingOrderList from "./list";
import PickingOrderForm from "../PickingOrder/form";
import PickingOrderCheck from "../PickingOrder/check";

const PickingOrder = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/all`}
        render={props => <PickingOrderList {...props} />}
      />
      <Route
        path={`${match.url}/create`}
        render={props => <PickingOrderForm {...props} orderType={"PickingOrder"} />}
      />
      <Route
        path={`${match.url}/edit`}
        render={props => <PickingOrderForm {...props} orderType={"PickingOrder"} />}
      />
      <Route
        path={`${match.url}/check`}
        render={props => <PickingOrderCheck {...props} orderType={"PickingOrder"} />}
      />
      <Redirect to="/transaction/picking-order/all" />
    </Switch>
);

export default PickingOrder;
