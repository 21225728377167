import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Container, Row, Label, Card, CardBody, Col, Input, Table, Button, ButtonGroup, CardFooter } from "reactstrap";
import * as Yup from "yup";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";
import Loading from "react-fullscreen-loading";

import queryString from 'query-string';
import moment from "moment";
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import apiUtil from '../../api/apiUtil';
import customerApi from "../../api/Customer";
import selfCollectionApi from "../../api/SelfCollection";

import EditItemDialog from './Partial/EditItemDialog';
import AddItemDialog from "./Partial/AddItemDialog";
import { zoneData, transportData } from '../../data/misc';

const SelfCollectionSchema = Yup.object({
  container_receipt: Yup.string().required('Please input value'),
  hawb: Yup.string().required('Please input value'),
  end_user_name: Yup.string().required('Please input value'),
  total_kg: Yup.number()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero')
    .required('Value is required'),
  total_package: Yup.number()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero')
    .required('Value is required'),
  delivery_to: Yup.string().required('Please input value'),
  remarks: Yup.string()
});

class SelfCollectionForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      addModal: false,
      editModal: false,
      editItemData: null,
      dataId: '',
      isLoading: false,
      pageLoading: false,
      customerList: [],
      departmentList: [],
      attentionList: [],
      selectedCustomer: {
        id: '0',
        name: '',
        code: '',
        departments: []
      },
      formData: {
        container_receipt: '',
        hawb: '',
        delivery_address_line1: '',
        delivery_address_line2: '',
        delivery_address_line3: '',
        delivery_postal_code: '',
        delivery_to: '',
        delivery_attention: '',
        delivery_contact_no: '',
        delivery_by: '',
        transport_type_id: '',
        attention_contact_no: '',
        delivery_man_power: 0,
        handling_out_date: new Date(),
        handling_out_time_start: "",
        handling_out_time_end: "",
        transport_no: "",
        parent_id: "",
        zone_id: 45,
        total_kg: 0,
        total_package: 0,
        enable_delivery_order: false,
        order_branch_id: 1,
        end_user_name: '',
        remarks: ''
      },
      order_details: []
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  componentDidMount = () => {
    this._isMounted = true;
    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        dataId: urlQuery.id
      });
      document.title = "Edit Self Collection | WMS";
    } else {
      document.title = "Create Self Collection | WMS";
    }

    this.setState({
      pageLoading: true,
    });

    window.scrollTo(0, 0);
    this.getCustomer();
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });

        if (this.state.dataId !== "") {
          this.getData(this.state.dataId);
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleCustomerChange = async (event) => {
    await customerApi.show(event.value).then(result => {
      let departmentList = [];

      if (Array.isArray(result.data.customer.departments)) {
        result.data.customer.departments.forEach(department => {
          departmentList.push({
            value: department.id,
            label: department.name,
            contacts: department.contact_people
          });
        });
      }

      this.setState({
        selectedCustomer: {
          id: event.value,
          name: event.name,
          code: event.label
        },
        departmentList: departmentList
      });
    }).catch(error => console.log(error));
  }

  customerDepartmentChange = event => {
    let attentionList = [];
    if (Array.isArray(event.contacts)) {
      event.contacts.forEach(contact => {
        attentionList.push({
          value: contact.first_name + " " + contact.last_name,
          label: contact.first_name + " " + contact.last_name
        });
      });
    }
    this.setState({
      attentionList: attentionList
    });
  }

  customerAttentionChange = event => {
    let formData = this.state.formData;
    formData.delivery_attention = event.value;
    this.setState({
      formData: formData
    });
  }

  customerDropOffChange = event => {
    let formData = this.state.formData;
    // formData.delivery_pic = event.value;
    formData.delivery_by = event.value;
    this.setState({
      formData: formData
    });
  }

  handleZoneChange = event => {
    let formData = this.state.formData;
    formData.zone_id = event.value;
    this.setState({
      formData: formData
    });
  }

  handleTransportChange = event => {
    let formData = this.state.formData;
    formData.transport_type_id = event.value;
    this.setState({
      formData: formData
    });
  }

  handleSubmit = values => {
    if (this.state.order_details.length <= 0) {
      apiUtil.toast('No items in this order.', 'error');
      return false;
    }

    if (this.state.selectedCustomer.id === "") {
      apiUtil.toast('No customer is selected.', 'error');
      return false;
    }

    values.order_details = this.state.order_details.filter(function (el) {
      return el.quantity != null;
    });

    if (values.order_details.length <= 0) {
      apiUtil.toast('No items in this order.', 'error');
      return false;
    }

    values.order_details.forEach((item, index) => {
      item.parent_id = item.id;
      item.child_id = item.id;
      values.order_details[index] = item;
    });

    values.customer_id = this.state.selectedCustomer.id;
    values.order_branch_id = 1;

    let formData = this.state.formData;
    for (const prop in formData) {
      if (values[prop] === undefined) {
        values[prop] = formData[prop];
      }
    }

    values.handling_out_date = moment(this.state.formData.handling_out_date).format('DD-MM-YYYY');
    values.handling_in = false;

    values.order_details.map(function(v,k){
      if(v.expiry_date){  v.expiry_date = moment(v.expiry_date).format('DD-MM-YYYY');}
    });
    
    if (this.state.dataId === "") { // create
      this.setState({
        isLoading: true
      });

      selfCollectionApi.create(values)
      .then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {
          apiUtil.toast('Self Collection created.', 'success');
          this.props.history.push('../self-collection/all');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      })
      .catch(error => apiUtil.toast(error.message, 'error'));
    } else { // update
      delete values.end_user_name;
      values.id = this.state.dataId;

      selfCollectionApi.update(this.state.dataId, values).then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {
          apiUtil.toast("Self Collection updated.", 'success');
          this.props.history.push('../self-collection/all');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      });
    }
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.handling_out_date = date;
    this.setState({
      formData: formData
    });
  }

  openItemDialog = () => {
    this.setState({
      addModal: true
    });
  }

  addItemDialog = () => {
    return <AddItemDialog
      modal={this.state.addModal}
      customer={this.state.selectedCustomer.id}
      addItem={(item) => {
        let exists = false;
        let orders = this.state.order_details;
        orders.forEach(orderItem => {
          if (item.id === orderItem.id) {
            exists = true;
            return false;
          }
        });

        if (exists) {
          apiUtil.toast('Item already added.', 'info');
          return false;
        }

        orders.push(item);

        this.setState({
          order_details: orders
        });

        apiUtil.toast('Item added', 'success');
      }}
      onClose={(data) => {
        this.setState({
          addModal: false
        });
      }}
    />
  }

  editItemDialog = () => {
    return <EditItemDialog
      modal={this.state.editModal}
      data={this.state.editItemData}
      onUpdate={(data) => {
        let orderData = this.state.order_details;

        if (typeof data === 'object' && data !== null) {
          let index = data.index;
          delete data.index;

          data.volume = this.calculateVolume(data.width, data.length, data.height);

          orderData[index] = data;
        }

        this.setState({
          editModal: false,
          editItemData: null,
          order_details: orderData
        });
      }}
    />
  }

  editItemOrder = index => {
    let orderData = this.state.order_details;
    orderData[index]['index'] = index;
    this.setState({
      editModal: true,
      editItemData: orderData[index]
    });
  }

  orderItemUpdate = (name, el) => {
    let orderItemData = this.state.orderItemData;
    orderItemData[name] = el;

    this.setState({
      orderItemData: orderItemData
    });
  }

  addItemToOrder = (item) => {
    if (Array.isArray(item)) {

      item.forEach(i => {
        i.volume = this.calculateVolume(i.width, i.length, i.height);
      });

      this.setState({
        order_details: item
      });
    } else {
      let orders = this.state.order_details;
      item.existing = false;
      item.volume = this.calculateVolume(item.width, item.length, item.height);
      orders.push(item);
      this.setState({
        order_details: orders
      });
    }
  }

  deleteItemOrder = index => {
    let orders = this.state.order_details;
    if (orders[index]['id'] !== undefined) {
      orders[index]['task'] = 'delete';
    } else {
      delete orders[index];
    }

    console.log(orders);
    this.setState({
      order_details: orders
    });
  }

  calculateVolume = (width, length, height) => {
    let val = (parseFloat(width) * parseFloat(length) * parseFloat(height) / 1000000);
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeTotal = (volume, qty) => { 
    let val = (parseFloat(volume) * parseFloat(qty));
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeGrandTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += (parseFloat(item.volume) *parseFloat(item.quantity));
    });

    let splitter = result.toString().split(".");
    return splitter.length > 1 &&  splitter[1].length > 6? parseFloat(result.toFixed(6)) : result;
  }

  getData = (dataId) => {
    selfCollectionApi.show(dataId).then(data => {
      if (data.status === 200) {
        this.populateData(data.data.result);
      } else {
        apiUtil.toast(data.message, 'error');
      }
    });
  }

  populateData = (data) => {
    let formData = this.state.formData;
    for (const prop in formData) {
      if (data[prop] !== undefined) {
        if (prop === 'handling_out_date') {
          formData.handling_out_date = new Date(data.handling_out_date);
        } else {
          formData[prop] = data[prop];
        }
      }
    }

    if (data.end_user !== undefined) {
      formData.end_user_id = data.end_user.id;
      formData.end_user_name = data.end_user.end_user;
    }

    let orderDetails = data.order_details;

    this.setState({
      pageLoading: false,
      formData: formData,
      order_details: orderDetails
    });

    this.handleCustomerChange({value: data.customer.id, name: data.customer.company_name, label: data.customer.company_code});
  }

  render() {
    const theClass = this;
    return (
      <Box className="dashboard-page">
        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? "Update Self Collection" : "Create Self Collection"}</h1>
        </StickyPageHeader>

        <Container>
        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              container_receipt: this.state.formData.container_receipt,
              hawb: this.state.formData.hawb,
              end_user_name: this.state.formData.end_user_name,
              total_kg: this.state.formData.total_kg,
              total_package: this.state.formData.total_package,
              delivery_to: this.state.formData.delivery_to,
              remarks: this.state.formData.remarks
            }}
            validationSchema={SelfCollectionSchema}
            onSubmit={this.handleSubmit}>
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              validateForm
            }) => (
              <Form>
                <Col md={{ size: 12 }}>
                  <Card className="card pb-0 mb-1 mt-2">
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <div className="mb-4">
                            <Label className="control-label">
                              Choose Company Code*
                            </Label>
                            <Select value={{label: this.state.selectedCustomer.code, value: this.state.selectedCustomer.id}} className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                          </div>

                          <div className="mb-4">
                            <Label className="control-label">
                              Company Name
                            </Label>
                            <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Date Out
                          </Label>
                          <div className="form-input">
                          <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.handling_out_date} onChange={date => this.changeDate(date)} />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            CR/PO/SO
                          </Label>
                          <Field className="form-control form-input" name="container_receipt" value={this.state.formData.container_receipt || ''} onChange={this.handleInputChange} />
                          {
                            errors.container_receipt && touched.container_receipt ? (
                            <div className="invalid-feedback d-block">
                            {errors.container_receipt}
                            </div>
                            ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Department
                          </Label>
                          <Select className="form-input no-border" options={this.state.departmentList} onChange={(e) => this.customerDepartmentChange(e)} />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Attention
                          </Label>
                          <Select value={{label: this.state.formData.delivery_attention, value: this.state.formData.delivery_attention}}  className="form-input no-border" options={this.state.attentionList} onChange={(e) => this.customerAttentionChange(e)} />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Attention PIC Contact
                          </Label>
                          <Field className="form-control form-input" name="attention_contact_no" value={this.state.formData.attention_contact_no || ''} onChange={this.handleInputChange} />
                          {
                            errors.attention_contact_no && touched.attention_contact_no ? (
                              <div className="invalid-feedback d-block">
                              {errors.attention_contact_no}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Address
                          </Label>
                          <Field className="form-control form-input" name="delivery_address_line1" value={this.state.formData.delivery_address_line1|| ''} onChange={this.handleInputChange} />
                          {
                            errors.delivery_address_line1 && touched.delivery_address_line1 ? (
                              <div className="invalid-feedback d-block">
                              {errors.delivery_address_line1}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Location
                          </Label>
                          <Select className="form-input no-border" options={zoneData} onChange={(e) => this.handleZoneChange(e)} />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                        <Label className="control-label">
                          Delivery To
                        </Label>
                        <Field className="form-control form-input" name="delivery_to" value={this.state.formData.delivery_to || ''} onChange={this.handleInputChange} />
                        {
                          errors.delivery_to && touched.delivery_to ? (
                            <div className="invalid-feedback d-block">
                            {errors.delivery_to}
                            </div>
                            ) : null
                        }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Drop Off PIC
                          </Label>
                          <Select value={{label: this.state.formData.delivery_by, value: this.state.formData.delivery_by}} className="form-input no-border" options={this.state.attentionList} onChange={(e) => this.customerDropOffChange(e)} />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Telephone
                          </Label>
                          <Field className="form-control form-input" name="delivery_contact_no" value={this.state.formData.delivery_contact_no || ''} onChange={this.handleInputChange} />
                          {
                            errors.delivery_contact_no && touched.delivery_contact_no ? (
                              <div className="invalid-feedback d-block">
                              {errors.delivery_contact_no}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            HAWB
                          </Label>
                          <Field className="form-control form-input" name="hawb" value={this.state.formData.hawb || ''} onChange={this.handleInputChange} />
                          {
                            errors.hawb && touched.hawb ? (
                              <div className="invalid-feedback d-block">
                              {errors.hawb}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Total Weight (kg)
                          </Label>
                          <Field className="form-control form-input" type="number" name="total_kg" value={this.state.formData.total_kg || ''} onChange={this.handleInputChange} placeholder="kg" />
                          {
                            errors.total_kg && touched.total_kg ? (
                              <div className="invalid-feedback d-block">
                              {errors.total_kg}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Total Packages
                          </Label>
                          <Field className="form-control form-input" type="number" name="total_package" value={this.state.formData.total_package || ''} onChange={this.handleInputChange} />
                          {
                            errors.total_package && touched.total_package ? (
                              <div className="invalid-feedback d-block">
                              {errors.total_package}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Transport Type
                          </Label>
                          <Select className="form-input no-border" options={transportData} onChange={this.handleTransportChange} defaultValue={0} />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            End User
                          </Label>
                          <Field className="form-control form-input" name="end_user_name" value={this.state.formData.end_user_name || ''} onChange={this.handleInputChange} />
                          {
                            errors.end_user_name && touched.end_user_name ? (
                              <div className="invalid-feedback d-block">
                              {errors.end_user_name}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={{ size: 12 }}>
                  <Card className="mt-2">
                    <CardBody>
                      <Col xs="12">
                        <h4 className="font-weight-bold mb-4">Items in this Order</h4>
                      </Col>
                      <Table className="mb-5">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Description</th>
                            <th>vol(m<sup>3</sup>)</th>
                            <th>Part No.</th>
                            <th>Lot No.</th>
                            <th>Serial No.</th>
                            <th>Qty</th>
                            <th>Expiry Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.order_details.map(function (item, index) {
                            if (item.task === undefined) {
                              return <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.description}</td>
                                  <td>{item.volume}</td>
                                  <td>{item.part_no}</td>
                                  <td>{item.lot_no}</td>
                                  <td>{item.serial_no}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.expiry_date}</td>
                                  <td>
                                    <ButtonGroup>
                                    <Button size="sm" color="info" onClick={e => theClass.editItemOrder(index)}>Edit</Button>
                                    <Button size="sm" color="danger" onClick={e => theClass.deleteItemOrder(index)}>Delete</Button>
                                    </ButtonGroup>
                                  </td>
                              </tr>
                            } else return "";
                          })}
                        </tbody>
                      </Table>
                    </CardBody>

                    <CardFooter>
                      <Button outline={true} onClick={(e) => this.openItemDialog()} color="success">Add Item</Button>
                    </CardFooter>
                  </Card>

                  <Card className="mt-4">
                    <ProgressButton 
                    type={'submit'}
                    color={'secondary'}
                    isLoading={this.state.isLoading}>
                      <Box pl={2} pr={2}>Submit</Box>
                    </ProgressButton>
                  </Card>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
        </Container>

        {this.addItemDialog()}
        {this.editItemDialog()}
      </Box>
    );
  }
}

export default SelfCollectionForm;