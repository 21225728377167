import React, { useState, useEffect, useContext } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { AuthContext } from "./Auth/AuthDataProvider";

import moment from "moment";

function WorkerReminder() {
  const [workerReminderModal, setWorkerReminderModal] = useState(false);

  const closeReminder = () => {
    setWorkerReminderModal(false);
  }

  useEffect(() => {
    let scope = localStorage.getItem("scope");

    if (scope && scope == 'worker') {
      let workerDailyReminderLastDate = localStorage.getItem('workerDailyReminderLastDate');
      if (!workerDailyReminderLastDate || moment().date() > moment(workerDailyReminderLastDate).date()) {
        setWorkerReminderModal(true);
        localStorage.setItem('workerDailyReminderLastDate', moment())
      }
    }
  });

  return (
    <Modal isOpen={workerReminderModal} centered={true} toggle={() => setWorkerReminderModal(false)}>
      <ModalHeader toggle={() => setWorkerReminderModal(false)}>
        Reminder!
      </ModalHeader>
      <ModalBody>
        Please remember to submit warehouse vehicle checklist.
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-success" onClick={() => closeReminder()}>Ok</button>
      </ModalFooter>
    </Modal>
  );
}

export default WorkerReminder;