import React, { Component } from "react";
import { 
  Typography
} from '@material-ui/core';
import { Container, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { locationData } from '../../../data/misc';

class AgingReportTable extends Component {
	constructor (props) {
		super(props);
		this.state = {
      title: this.props.reportData.result.Title,
			reports: this.props.reportData.result.Data
		}
	}

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportData.result.Title !== this.state.title) {
      this.setState({
        title: nextProps.reportData.result.Title,
        reports: nextProps.reportData.result.Data
      });
    }
  }

	render () {
		const columns = [
      {
        name: "Sales_Ref_No.",
        label: "Sales Ref No."
      },
      {
        name: "CR/PO/SO.",
        label: "CR/PO/SO"
      },
      {
        name: "Part_N0.",
        label: "Part No."
      },
      {
        name: "Lot_No.",
        label: "Lot No."
      },
      {
      	name: "Location",
      	label: "Location",
        options: {
          customBodyRender: (value) => {
            let val = '';
            locationData.map(function(v,k){ if(v.value==value){ val = v.label;} });
            return val;
          }
        }
      },
      {
        name: "Balance",
        label: "Balance"
      },
      {
        name: "Expiry_Date",
        label: "Expiry Date",
        options: {
          customBodyRender: (value) => {
            let val = value ? moment(value).format('DD-MM-YYYY'):'';
            return val;
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 10,
      serverSide: false
    }

    return (
    	<Container fluid={true}>
        <Row className="mt-3">
          <Col xs="12">
            <MUIDataTable
              title={
                <Typography variant="h6">
                {this.state.title}
                </Typography>
              }
              className="mt-3"
              data={this.state.reports}
              columns={columns}
              options={options}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AgingReportTable;