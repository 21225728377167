import React, { Component } from "react";

import {
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import MUIDataTable from "mui-datatables";
import { MuiThemeProvider } from '@material-ui/core/styles';
import TableTheme from '../../components/Table/Theme';
import Icon from "@material-ui/core/Icon";

import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';
import { ReactComponent as ExportIcon } from '../../assets/img/icons/export.svg';
import { ReactComponent as ImporttIcon } from '../../assets/img/icons/import.svg';
import { ReactComponent as ConvertToDOIcon } from '../../assets/img/icons/convert_to_do.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import PreviewDialog from "./Partial/FormPreviewDialog";

import queryString from 'query-string';
import Select from 'react-select';
import { Row, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import pickingOrderApi from "../../api/PickingOrder";
import deliveryOrderApi from "../../api/DeliveryOrder";

import moment from "moment";

class PickingOrderList extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.pageName = "pickingorder_list_page";
    this.customerSelectStorageSetting = "_customer_select";

    this.state = {
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 50,
        sortOrder: {}
      },
      search: '',
      data: [],
      total: 0,
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        id: '0',
        name: '',
        value: '0',
        label: ''
      },
      isFilterOpen: false,
      filter: {
        customerId: null,
        text: 'Choose..',
        field: 'query',
        value: ''
      },
      hideCustomerFilter: false,
      previewModal: false,
      selecedItem: null
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;
    tableState.rowsPerPage = rowsPerPage;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  getData = async (customerId = null) => {
    if (customerId === null) {
      if (this.state.selectedCustomer.id === '0') {
        apiUtil.toast('No customer selected.', 'error');
        return false;
      }
      customerId = this.state.selectedCustomer.id;
    }

    this.setState({ isLoading: true });

    let params = {
      customer_id: customerId,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value,
      page: this.state.tableState.page + 1,
      take: this.state.tableState.rowsPerPage,
      query: this.state.search
    };

    await pickingOrderApi.get(params).then(data => {
      this.setState({
        isLoading: false,
        data: data.data,
        total: data.total
      });
    }).catch(error => console.log(error));
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        }, () => {
          let customerSelectFilterStr = window.localStorage.getItem(this.pageName + this.customerSelectStorageSetting);
          if (customerSelectFilterStr) {
            let customerSelectFilter = JSON.parse(customerSelectFilterStr);
            this.setState({
              selectedCustomer: {
                id: customerSelectFilter.id,
                name: customerSelectFilter.name,
                value: customerSelectFilter.value,
                label: customerSelectFilter.label
              }
            });
            this.handleCustomerChange({
              value: customerSelectFilter.id,
              name: customerSelectFilter.name,
              label: customerSelectFilter.label
            });
          }
        });

        // let urlQuery = queryString.parse(window.location.search);
        // if (urlQuery.customer !== undefined) {
        //   let customer = this.state.customerList.filter(p => p.value == urlQuery.customer);
        //   if(customer && customer.length > 0) {
        //     this.setState({
        //       selectedCustomer:{
        //         id: customer[0].value,
        //         name: customer[0].name
        //       }
        //     });
        //     this.handleCustomerChange({
        //         value: customer[0].value,
        //         name: customer[0].name
        //     });
        //     console.log(this.state.selectedCustomer);
        //   }
        // }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name,
        value: event.value,
        label: event.label
      }
    }, () => {
      window.localStorage.setItem(this.pageName + this.customerSelectStorageSetting, JSON.stringify(this.state.selectedCustomer));
    });

    this.getData(event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleSearchFieldChange = (event) => {
    this.setState({
      search: event.target.value
    });
  }

  handleConvertDoOrder = (id) => {
    this.setState({
      isLoading: true
    }, () => {
      deliveryOrderApi.convertDO(id)
        .then(result => {
          this.setState({
            isLoading: false,
          });

          if (result.status == 200) {
            apiUtil.toast('Converted to DO!', 'success');
            this.getData();
          } else {
            apiUtil.toast(result.errorMessage, 'error');
          }
        });
    });
  }

  componentDidMount = () => {
    this._isMounted = true;

    document.title = "All Picking Order | WMS";
    window.scrollTo(0, 0);

    let customer = JSON.parse(localStorage.getItem("customer"));

    if (customer) {
      this.setState({
        selectedCustomer: {
          id: customer.id,
          name: customer.company_name,
          label: customer.company_code
        },
        hideCustomerFilter: true
      }, () => {
        this.getData(customer.id);
      });
    } else {
      this.getCustomer();
    }
  }

  removeDialog = () => {
    return <RemoveDialog
      modal={this.state.modal}
      onRemove={(isRemove) => {
        if (isRemove) {
          let data = this.state.data.filter(r => r.id !== this.state.modalItem);

          pickingOrderApi.delete(this.state.modalItem).then(data => {
            //console.log(data);
            if (data.data.status === true) {
              apiUtil.toast(data.data.message, 'success');
            } else {
              apiUtil.toast(data.data.message, 'warning');
            }
          }).catch(err => {
            apiUtil.toast(err.message, 'error');
          });

          this.setState({
            data: data
          });
        }

        this.setState({
          modal: false,
          modalItem: null
        });
      }}
    />
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addNewButton = () => {
    if (apiUtil.checkAccess("PickingOrderCreate")) {
      return <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} />;
    }
    else {
      return '';
    }
  }

  tableActions = (access, button) => {
    if (!access) {
      return button;
    } else if (apiUtil.checkAccess(access)) {
      return button;
    }

    return '';
  }

  deleteData = id => {
    this.setState({
      modal: true,
      modalItem: id
    });
  }

  openPreviewDialog = (rowIndex) => {
    var item = this.state.data[rowIndex];
    this.setState({
      selectedItem: item
    }, () => {
      this.setState({
        previewModal: true
      });
    });
  }

  previewDialog = () => {
    return <PreviewDialog
      modal={this.state.previewModal}
      item={this.state.selectedItem}
      onClose={() => {
        this.setState({
          previewModal: false
        });
      }}
    />
  }

  render() {
    const columns = [
      {
        name: "reference_no",
        label: "Pick Order Ref No",
        options: {
          customBodyRender: (value, data) => {
            return <button type="button" className="bg-transparent border-0 text-underline p-0"
              onClick={() => this.openPreviewDialog(data.rowIndex)}>
              {value}
            </button>
          }
        }
      },
      {
        name: "document_no",
        label: "Document No"
      },
      {
        name: "delivery_address_line1",
        label: "Delivery Address"
      },
      {
        name: "created_at",
        label: "Date",
        options: {
          customBodyRender: (value) => {
            return value ? moment(value).format('DD-MM-YYYY') : '';
          }
        }
      },
      {
        name: "end_user_name",
        label: "End User"
      },
      {
        name: "handling_out_date",
        label: "Estimated Delivery Date",
        options: {
          customBodyRender: (value) => {
            return value ? moment(value).format('DD-MM-YYYY') : '';
          }
        }
      },
      // {
      //   name: "order_status",
      //   label: "Status",
      //   options: {
      //     customBodyRender: (value) => {
      //       return <>{value?.status}</>
      //     }
      //   }
      // },
      // {
      //   name: "do_number",
      //   label: "Delivery Reference No"
      // },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRender: (id, data) => {
            let access_token = window.localStorage.getItem("access_token");

            return <div className="btn-group d-flex align-items-center">
              {this.tableActions(null, <Tooltip title="Download PDF">
                <a href={deliveryOrderApi.baseApiUrl() + 'api/pdf/orders/picking_order_reference/' + id + '.pdf?authorization=' + access_token} target="_blank" className="pt-2 mr-2">
                  <Icon
                    component={"i"}
                    style={{ fontSize: "20px", color: "#4e4e4e" }}
                  >
                    insert_drive_file
                  </Icon>
                </a>
              </Tooltip>)}
              {this.tableActions("PickingOrderUpdate", <Tooltip title="Convert To Delivery Order">
                <button type="button"
                  className="px-0 mr-3 border-0 bg-transparent"
                  onClick={() => this.handleConvertDoOrder(id)}>
                  <ConvertToDOIcon style={{ width: "28px" }} />
                </button>
              </Tooltip>)}
              {this.tableActions("PickingOrderView", <Tooltip title="Edit">
                <a href={'./edit?id=' + id} className=" mr-2">
                  <EditIcon className="fix-icon-color" />
                </a>
              </Tooltip>)}
              {this.tableActions("PickingOrderDelete", <Tooltip title="Delete">
                <button type="button" onClick={() => this.deleteData(id)} className="border-0 bg-transparent">
                  <DeleteIcon />
                </button>
              </Tooltip>)}
            </div>;
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      serverSide: true,
      count: this.state.total,
      download: false,
      print: false,
      search: false,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'changeRowsPerPage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          default:
          //console.log('action not handled.');
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        columns.splice(-1, 1);
        data.map(function (item, index) {
          item.data.splice(-1, 1);
          item.data[1] = moment(item.data[1]).format('DD-MM-YYYY');
        });
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: { filename: 'Picking Order - ' + this.state.selectedCustomer.name + '.csv', separator: ',' }
    }

    const searchInput = () => {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          this.setState({
            search: event.target.value
          }, () => {
            this.search(0);
          });
        }
      }

      const onBlurHandler = (event) => {
        this.setState({
          search: event.target.value
        });
      }

      return <Input className="form-control form-input" name="searchInput" onKeyDown={handleKeyDown} onBlur={onBlurHandler} />
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <BreadCrumb />
        <h1 className="page-title">Picking Order</h1>
        {this.addNewButton()}
      </StickyPageHeader>

      <Card className="list-search-box">
        <CardBody>
          <Row>
            <Col md={{ size: 6, offset: 2 }}>
              <div className={this.state.hideCustomerFilter ? "d-none" : "clearfix"}>
                <Label className="control-label">
                  Choose Company Code*
                </Label>
                <Select value={this.state.selectedCustomer}
                  className="form-input has-margin no-border"
                  options={this.state.customerList}
                  onChange={(e) => this.handleCustomerChange(e)}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Search
                </Label>
                {/* <InputGroup className="form-input-group no-border">
                  <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.isFilterOpen} toggle={this.toggleFilter}>
                    <DropdownToggle caret>{this.state.filter.text}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem value="query" onClick={this.handleFilterChange}>Choose..</DropdownItem>
                      <DropdownItem value="container_receipt" onClick={this.handleFilterChange}>CR/PR/SO</DropdownItem>
                      <DropdownItem value="reference_no" onClick={this.handleFilterChange}>Ref No</DropdownItem>
                      <DropdownItem value="lot_no" onClick={this.handleFilterChange}>Lot No</DropdownItem>
                      <DropdownItem value="serial_no" onClick={this.handleFilterChange}>Serial No</DropdownItem>
                      <DropdownItem value="hawb" onClick={this.handleFilterChange}>HAWB</DropdownItem>
                      <DropdownItem value="part_no" onClick={this.handleFilterChange}>Part No</DropdownItem>
                      <DropdownItem value="location" onClick={this.handleFilterChange}>Location</DropdownItem>
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <Input placeholder="optional.." onChange={this.handleFilterFieldChange} />
                </InputGroup>
                <Button
                  className="btn-theme form-input-btn mt-2 mt-md-0"
                  onClick={() => this.search(0, '')}
                  color="primary"
                  variant="contained">Search</Button> */}
                {searchInput()}
              </div>

              <div className="clearfix">
                <Label className="control-label">{' '}</Label>
                <Button
                  className="btn-theme mt-2 mt-md-0"
                  onClick={() => this.search(0)}
                  color="primary"
                  variant="contained">Search</Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              <MuiThemeProvider theme={TableTheme}>
                <MUIDataTable
                  title={
                    <Typography variant="h6">
                      Picking Order
                      {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                    </Typography>}
                  data={this.state.data}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.removeDialog()}
      {this.previewDialog()}
    </Box>;
  }
}

export default PickingOrderList;