import React, { Component } from "react";

import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ButtonGroup,
  Button,
  Input,
  Col,
  Table
} from "reactstrap";

import MUIDataTable from "mui-datatables";
import salesOrderApi from "../../../api/SalesOrder";
import apiUtil from "../../../api/apiUtil";

import moment from "moment";

class FormPreviewDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    console.log(props);
    let item = props.item ? props.item : null;
    this.state = {
      modal: modal,
      isLoading: false,
      data: null,
      tableState: {
        page: 1,
        rowsPerPage: 100,
        sortOrder: {}
      },
      searchTerm: '',
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }

    if (nextProps.item !== this.state.item) {
      this.setState({
        item: nextProps.item
      })
      this.getData(nextProps.item.id)
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  getData = (dataId) => {
    salesOrderApi.show(dataId).then(data => {
      if (data.status === 200) {
        this.setState({
          data: data.data.result
        });
      } else {
        apiUtil.toast(data.message, 'error');
      }
    });
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="xl" zIndex={1300}>
        <ModalBody>
          <Row>
            <Col xs="12" className="mt-3">
              <Typography variant="h6">
                History
              </Typography>
              <Table className="mb-5">
                <thead>
                  <tr>
                    <th>Details</th>
                    <th>Updated By</th>
                    <th>Updated At</th>
                    <th>Last Status</th>
                    <th>Current Status</th>
                  </tr>
                </thead>

                <tbody>
                  {this.state.data ? this.state.data.order_histories.sort((a, b) => a.updated_at < b.updated_at ? 1 : -1).map(function (item, index) {
                    return <tr key={index}>
                      <td><div dangerouslySetInnerHTML={{ __html: item.details }} /></td>
                      <td>{item.worker_id ? item.worker.first_name + ' ' + item.worker.last_name : item.admin ? item.admin.first_name : ""}</td>
                      <td>{item.updated_at ? moment(moment.utc(item.updated_at).toDate()).format('MMMM D, YYYY HH:mm A') : ""}</td>
                      <td>{item.order_status ? item.order_status.status : ""}</td>
                      <td>{item.current_status ? item.current_status.status : ""}</td>
                    </tr>
                  }) : ""}
                </tbody>
              </Table>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={this.handleClose}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default FormPreviewDialog;