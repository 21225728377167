import React from "react";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Route } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const breadcrumbNameMap = {
  '/customers': 'Customers',
  '/customers/all-customers': 'All Customers',
  '/customers/create-customer': 'Create Customer',
  '/customers/edit-customer': 'Edit Customer',

  '/vehicles': 'Vehicles',
  '/vehicles/all-vehicles': 'All Vehicles',
  '/vehicles/edit-vehicle': 'Edit Vehicle',

  '/transaction': 'Transactions',
  '/transaction/sales-order': 'Sales Order',
  '/transaction/sales-order/all': 'All Sales Order',
  '/transaction/sales-order/create': 'Create Sales Order',
  '/transaction/sales-order/edit': 'Edit Sales Order',
  
  '/transaction/picking-order': 'Picking Order',
  '/transaction/picking-order/all': 'All Picking Order',
  '/transaction/picking-order/create': 'Create Picking Order',
  '/transaction/picking-order/edit': 'Edit Picking Order',

  '/transaction/delivery-order': 'Delivery Order',
  '/transaction/delivery-order/all': 'All Delivery Order',
  '/transaction/delivery-order/create': 'Create Delivery Order',
  '/transaction/delivery-order/edit': 'Edit Delivery Order',
  '/transaction/delivery-order/check': 'Order Check Out',

  '/transaction/self-collection': 'Self Collection',
  '/transaction/self-collection/all': 'All Self Collection',
  '/transaction/self-collection/create': 'Create Self Collection',
  '/transaction/self-collection/edit': 'Edit Self Collection',

  '/transaction/balance-enquiry': 'Enquiry',
  '/transaction/balance-enquiry/list': 'Balance Enquiry',
  '/transaction/balance-enquiry/all': 'Balance Enquiry All',

  '/report': 'Report',
  '/report/in-out': 'Rental Report',
  '/report/monthly-summary': 'Monthly Report',
  '/report/aging': 'Aging Report',
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BreadcrumbContainer = () => {
  return <div className="breadcrumb-container">
    <Route>
    {({ location }) => {
      const pathnames = location.pathname.split('/').filter((x) => x);
      
      return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <LinkRouter color="inherit" to="/">
          Home
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;

            return last ? (
              <Typography color="textPrimary" key={to}>
                {breadcrumbNameMap[to]}
              </Typography>
              ) : (
              <LinkRouter color="inherit" to={to} key={to}>
                {breadcrumbNameMap[to]}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      );
    }}
    </Route>
  </div>;
};


export default BreadcrumbContainer;
