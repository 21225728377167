import React, { Component } from "react";
import {
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';

import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';

import { Card, CardBody, Row, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableState: {
        page: 1,
        rowsPerPage: 50,
        sortOrder: {},
        search: ''
      },
      data: [],
      total: 0,
      isLoading: false
    };
  }

  componentDidMount() {
    document.title = "All Customers | WMS";
    window.scrollTo(0, 0);
    this.getData();
  }

  getData = async () => {
    this.setState({ isLoading: true });
    let params = {
      page: this.state.tableState.page + 1,
      take: this.state.tableState.rowsPerPage
    };

    if (this.state.tableState.search !== '') {
      params.search = this.state.tableState.search;
    }

    await customerApi.get(params).then(data => {
      if (data.errorMessage !== '') {
        apiUtil.toast(data.errorMessage, 'error');
      } else {
        this.setState({
          isLoading: false,
          data: data.data,
          total: data.total
        });
      }
    }).catch(error => console.log(error));
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;
    tableState.rowsPerPage = rowsPerPage;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  removeDialog = () => {
    return <RemoveDialog
      modal={this.state.modal}
      onRemove={(isRemove) => {
        if (isRemove) {
          let data = this.state.data.filter(r => r.id !== this.state.modalItem);

          customerApi.delete(this.state.modalItem).then(data => {
            if (data.data.status === true) {
              apiUtil.toast(data.data.result, 'success');
            } else {
              apiUtil.toast(data.data.result, 'warning');
            }
          }).catch(err => {
            apiUtil.toast(err.message, 'error');
          });

          this.setState({
            data: data
          });
        }

        this.setState({
          modal: false,
          modalItem: null
        });
      }}
    />
  }

  addNewButton = () => {
    if (apiUtil.checkAccess("CustomerCreate")) {
      return <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create-customer')} />
    }
    else {
      return '';
    }
  }

  deleteData = id => {
    this.setState({
      modal: true,
      modalItem: id
    });
  }

  render() {
    const columns = [
      {
        name: "company_code",
        label: "Code"
      },
      {
        name: "company_name",
        label: "Company Name"
      },
      {
        name: "address",
        label: "Address"
      },
      {
        name: "contact_no1",
        label: "Contact"
      },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRender: (id, data) => {
            return (
              <div className="btn-group">
                <a href={'./edit-customer?id=' + id}>
                  <EditIcon className="fix-icon-color" />
                </a>
                <button type="button" onClick={() => this.deleteData(id)}>
                  <DeleteIcon />
                </button>
              </div>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      serverSide: true,
      count: this.state.total,
      download: false,
      print: false,
      customSearchRender: debounceSearchRender(500),
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'search':
            this.search(tableState.page, tableState.searchText);
            break;
          case 'changeRowsPerPage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          default:
            console.log('action not handled.');
        }
      }
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <BreadCrumb />
        <h1 className="page-title">Customer List</h1>        
        {this.addNewButton()}
      </StickyPageHeader>

      <Card>
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    All Customers
                    {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.removeDialog()}
    </Box>;
  }
}

export default CustomerList;