import React, { Component } from "react";

import {
  Box,
  CircularProgress
} from '@material-ui/core';
import { Container, Row, Label, Card, CardBody, Col, Input, Table, Button, ButtonGroup, CardFooter } from "reactstrap";
import * as Yup from "yup";

import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import TableTheme from '../../components/Table/Theme';
import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";
import Loading from "react-fullscreen-loading";

import queryString from 'query-string';
import moment from "moment";
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import apiUtil from '../../api/apiUtil';
import customerApi from "../../api/Customer";
import deliveryOrderApi from "../../api/DeliveryOrder";
import salesOrderApi from "../../api/SalesOrder";

import EditItemDialog from './Partial/EditItemDialog';
import AddItemDialog from "./Partial/AddItemDialog";
import ImportItemDialog from "./Partial/ImportItemDialog";
import RemoveDialog from '../../components/Dialog/RemoveDialog';
import { zoneData } from '../../data/misc';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

const deliveryOrderSchema = Yup.object({
  //container_receipt: Yup.string().required('Please input value'),
  //hawb: Yup.string().required('Please input value'),
  //end_user_name: Yup.string().required('Please input value'),
  total_kg: Yup.number()
    .typeError('Value must be a number'),
  //.positive('Value must be greater than zero'),
  //.required('Value is required'),
  // total_package: Yup.number()
  //   .typeError('Value must be a number'),
  //.positive('Value must be greater than zero'),
  //.required('Value is required'),
  // delivery_to: Yup.string().required('Please input value'),
  remarks: Yup.string()
});

const theme = createMuiTheme({
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#ced4da"
        },
        "&:hover $notchedOutline": {
          borderColor: "#ced4da"
        },
        "&$focused $notchedOutline": {
          borderColor: "#64CCC9",
          boxShadow: '0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(100 204 201 / 60%)',
          outline: '0 none',
          borderSize: '0.2'
        }
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: 1
        }
      }
    }
  }
});

class PickingOrderForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      readOnly: false,
      canSave: false,
      orderType: props.orderType,
      addModal: false,
      editModal: false,
      removeAllModal: false,
      importModal: false,
      editItemData: null,
      dataId: '',
      isLoading: false,
      pageLoading: false,
      customerList: [],
      departmentList: [],
      attentionList: [],
      branchList: [],
      poTypes: [],
      poCategories: [],
      transportTypes: [],
      poTypeLabel: '',
      po_type_id: null,
      orderCategoryLabel: '',
      order_category_id: null,
      transportTypeLabel: '',
      transportTypeId: null,
      selectedCustomer: {
        id: '0',
        name: '',
        code: '',
        departments: []
      },
      formData: {
        container_receipt: '',
        hawb: '',
        delivery_address_line1: '',
        delivery_address_line2: '',
        delivery_address_line3: '',
        delivery_postal_code: '',
        delivery_to: '',
        delivery_attention: '',
        delivery_pic: '',
        contact_person_id: null,
        delivery_contact: null,
        delivery_contact_no: '',
        delivery_by: '',
        document_no: '',
        delivery_timing: '',
        delivery_remarks: '',
        end_user_name: '',
        end_user_contact_no: '',
        transport_type_id: '',
        attention_contact_no: '',
        delivery_man_power: 0,
        initial_handling_out_date: null,
        handling_out_date: null,
        handling_out_time_start: "",
        handling_out_time_end: "",
        transport_no: "",
        parent_id: "",
        zone_id: 45,
        total_kg: 0,
        total_package: 0,
        enable_delivery_order: true,
        order_branch_id: 1,
        remarks: '',
        do_id: '',
        po_type_id: 0,
        po_type: null,
        orderCategoryLabel: '',
        order_category_id: null,
        reference_no: '',
        do_number: '',
        item_grouping: null
      },
      order_details: [],
      order_details_deleted: [],
      sales_order_items: []
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  componentDidMount = () => {
    this._isMounted = true;
    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        dataId: urlQuery.id
      });
      document.title = "Edit Picking Order | WMS";
    } else {
      document.title = "Create Picking Order | WMS";
    }

    this.setState({
      pageLoading: true,
    });

    window.scrollTo(0, 0);

    let scope = localStorage.getItem("scope");

    if (scope != "admin") {
      this.setState({ readOnly: true }, () => {
        this.getPOTypes();
        this.getPOCategories();
        this.getTransportTypes();
        this.getCustomer();

        if (scope == 'worker') {
          this.setState({
            canSave: true
          })
        }
      });
    } else {
      this.getPOTypes();
      this.getPOCategories();
      this.getTransportTypes();
      this.getCustomer();
      this.getSalesOrderItems();
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.dataId !== this.state.dataId && this.state.orderType !== "PickingOrder") {
      this.setState({ readOnly: true })
    }
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });

        if (this.state.dataId !== "") {
          this.getData(this.state.dataId);
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  getSalesOrderItems = async () => {
    if (this.state.selectedCustomer.id != '0') {
      await salesOrderApi.getDetail({
        page: 1,
        take: 99999,
        customer_id: this.state.selectedCustomer.id
      }).then(res => {
        if (res.status !== 200) {
          apiUtil.toast(res.errorMessage, 'error');
          return false;
        } else {
          let items = [];
          res.data.result.forEach(item => {
            items.push(item);
          });

          this.state.sales_order_items = items;
        }
      })
        .catch(error => apiUtil.toast(error.message, 'error'));
    }
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleSerialNoChange = (event, idx) => {
    let itemDetails = this.state.order_details;
    itemDetails[idx].serial_no = event.target.value;
    itemDetails[idx].is_duplicated = null;

    for (let i = 0; i < itemDetails.length; i++) {
      itemDetails[i].is_duplicated = null;
      for (let j = 0; j < itemDetails.length; j++) {
        if (i != j && !apiUtil.isNullOrWhitespace(itemDetails[i].serial_no)
          && !apiUtil.isNullOrWhitespace(itemDetails[j].serial_no)
          && itemDetails[i].serial_no == itemDetails[j].serial_no) {
          itemDetails[i].is_duplicated = true;
          itemDetails[j].is_duplicated = true;
        }
      }
    }

    this.setState({
      order_details: itemDetails
    });
  }

  handleSerialNoKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);

      if (form.elements[index + 1] && form.elements[index + 1].tagName === "INPUT") {
        form.elements[index + 1].focus();
      } else if (form.elements[index + 2] && form.elements[index + 2].tagName === "INPUT") {
        form.elements[index + 2].focus();
      }
    }
  }

  handleCustomerChange = async (event) => {
    //console.log("handling", event.value);
    //console.log('customer change', event);
    await customerApi.show(event.value).then(result => {
      let departmentList = [];
      let branchList = [];
      let attentionList = [];
      //console.log(result.data.customer);
      if (Array.isArray(result.data.customer.departments)) {
        result.data.customer.departments.forEach(department => {
          departmentList.push({
            value: department.id,
            label: department.name,
            contacts: department.contact_people
          });
        });

        if (departmentList.length > 0) {
          let formData = this.state.formData;
          formData.department_id = departmentList[0].value;
          formData.department = departmentList[0].label;

          if (Array.isArray(departmentList[0].contacts)) {
            departmentList[0].contacts.forEach(contact => {
              if (contact.first_name || contact.last_name) {
                attentionList.push({
                  value: ((contact.first_name ?? '') + " " + (contact.last_name ?? '')).trim(),
                  label: ((contact.first_name ?? '') + " " + (contact.last_name ?? '')).trim(),
                  contact: contact
                });
              }
            });

            if (attentionList.length > 0) {
              let personInCharge = attentionList[0].value;

              let recipient = personInCharge;
              let phone = attentionList[0].contact.contact_no;

              formData.delivery_attention = personInCharge;
              formData.delivery_pic = personInCharge;
              formData.contact_person_id = attentionList[0].contact.id;
              formData.delivery_contact = attentionList[0].contact;
              formData.delivery_contact_no = phone;
            }
          }
        }
      }

      if (Array.isArray(result.data.customer.customer_addresses)) {
        result.data.customer.customer_addresses.forEach(address => {
          branchList.push({
            value: address.id,
            label: address.name,
            contacts: address.contact_name,
            address_line1: address.address_line1,
            address_line2: address.address_line2
          });
        });

        if (branchList.length > 0) {
          let formData = this.state.formData;
          formData.customer_address_id = branchList[0].value;
          formData.branchName = branchList[0].label;
          formData.branchAddress = branchList[0].address_line1 + ' ' + branchList[0].address_line2;
          //formData.delivery_contact_no = branchList[0].contact_no;
        }
      }

      if (this.state.formData.department_id) {
        let departs = departmentList;
        let formData = this.state.formData;
        departs.map(function (item, index) {
          if (item.value == formData.department_id) { formData.department_id = item.value; formData.department = item.label; }
        });
      }
      if (this.state.formData.po_type_id) {
        let formData = this.state.formData;

        const poTypes = this.state.poTypes.map(function (item, index) {
          //console.log("item", item)
          if (item.value == formData.po_type_id) {
            formData.poTypeLabel = item.label;
            formData.po_type_id = item.value;
          }

        })
        this.setState({
          poTypeLabel: formData.poTypeLabel,
          po_type_id: formData.po_type_id
        })
      }
      if (this.state.formData.order_category_id) {
        let formData = this.state.formData;

        const poCategories = this.state.poCategories.map(function (item, index) {
          //console.log("item", item)
          if (item.value == formData.order_category_id) {
            formData.orderCategoryLabel = item.label;
            formData.order_category_id = item.value;
          }

        })
        this.setState({
          orderCategoryLabel: formData.orderCategoryLabel,
          order_category_id: formData.order_category_id
        })
      }
      if (this.state.formData.customer_address_id) {
        let formData = this.state.formData;
        branchList.map(function (item, index) {
          if (item.value == formData.customer_address_id) {
            formData.customer_address_id = item.value;
            formData.branchName = item.label;
            formData.branchAddress = item.address_line1 + ' ' + item.address_line2;
            // formData.delivery_attention = event.contacts[0].first_name + " " + event.contacts[0].last_name != null ? event.contacts[0].last_name : '';
            // formData.delivery_pic = event.contacts[0].first_name + " " + event.contacts[0].last_name != null ? event.contacts[0].last_name : '';
            // formData.delivery_contact_no = event.contacts[0].contact_no;
          }
        });
      } else {
        let formData = this.state.formData;
        formData.department = null;
        formData.customer_address_id = null;
        formData.branchAddress = null;
        formData.delivery_attention = null;
        formData.delivery_pic = null;
        formData.delivery_contact_no = null;
      }

      let formData = this.state.formData;
      formData.delivery_contact_no = result.data.customer.contact_no1;
      formData.item_grouping = result.data.customer.item_grouping;

      this.setState({
        selectedCustomer: {
          id: event.value,
          name: event.name,
          code: event.label
        },
        departmentList: departmentList,
        branchList: branchList,
        attentionList: attentionList
      });

      this.getSalesOrderItems();
    }).catch(error => console.log(error));
  }

  customerDepartmentChange = event => {
    let attentionList = [];
    let personInCharge = null;
    let recipient = null;
    let phone = null;
    let phoneList = [];
    if (Array.isArray(event.contacts)) {
      event.contacts.forEach(contact => {
        attentionList.push({
          value: contact.first_name + " " + contact.last_name,
          label: contact.first_name + " " + contact.last_name,
          contact: contact
        });
      });

      if (event.contacts.length > 0) {
        //console.log("event.contacts[0].last_name", event.contacts[0].first_name)
        personInCharge = event.contacts[0].first_name;
        if (event.contacts[0].last_name) {
          personInCharge = personInCharge + " " + event.contacts[0].last_name;
        }
        recipient = personInCharge;
        phone = event.contacts[0].contact_no;
      }
    }

    let formData = this.state.formData;
    formData.department_id = event.value;
    formData.department = event.label;
    formData.delivery_attention = personInCharge
    formData.delivery_pic = personInCharge
    formData.delivery_contact_no = phone

    this.setState({
      attentionList: attentionList,
      formData: formData,
      delivery_attention: personInCharge,
      delivery_pic: personInCharge,
      delivery_contact_no: phone
    });
  }

  customerBranchNameChange = event => {
    let attentionList = [];
    // if (Array.isArray(event.contacts)) {
    //   event.contacts.forEach(contact => {
    //     attentionList.push({
    //       value: contact.first_name + " " + contact.last_name,
    //       label: contact.first_name + " " + contact.last_name
    //     });
    //   });
    // }

    let formData = this.state.formData;
    formData.customer_address_id = event.value;
    formData.branchId = event.value;
    formData.branchName = event.label;
    formData.branchAddress = event.address_line1 + ' ' + event.address_line2;
    //console.log("event", event);
    this.setState({
      formData: formData,
    });
  }

  customerAttentionChange = (event, value) => {
    if (typeof value === 'string' && value) {
      let formData = this.state.formData;
      formData.delivery_attention = value;
      formData.delivery_pic = value;
      this.setState({
        formData: formData
      });
    }

    if (!value) {
      let formData = this.state.formData;
      formData.delivery_attention = event.value;
      formData.delivery_pic = event.value;
      formData.contact_person_id = event.contact.id;
      formData.delivery_contact = event.contact;
      this.setState({
        formData: formData
      });
    }

  }

  customerDropOffChange = (event, value) => {
    if (typeof value === 'string' && value) {
      let formData = this.state.formData;
      formData.delivery_by = value;
      this.setState({
        formData: formData
      });
    }

    if (!value) {
      let formData = this.state.formData;
      formData.delivery_by = event.target.value;
      this.setState({
        formData: formData
      });
    }
  }

  handlePoType = (event, value) => {
    let formData = this.state.formData;
    if (event) {
      formData.po_type_id = event.value;
      formData.poTypeLabel = event.label;
      this.setState({
        formData: formData,
        poTypeLabel: event.label,
        po_type_id: event.value
      });
    } else {
      formData.po_type_id = null;
      formData.poTypeLabel = '';
      this.setState({
        formData: formData,
        poTypeLabel: '',
        po_type_id: null
      });
    }
  }

  handlePoCategory = (event, value) => {
    let formData = this.state.formData;
    if (event) {
      formData.order_category_id = event.value;
      formData.orderCategoryLabel = event.label;
      this.setState({
        formData: formData,
        orderCategoryLabel: event.label,
        order_category_id: event.value
      });
    } else {
      formData.order_category_id = null;
      formData.orderCategoryLabel = '';
      this.setState({
        formData: formData,
        orderCategoryLabel: '',
        order_category_id: null
      });
    }
  }

  handleZoneChange = event => {
    let formData = this.state.formData;
    formData.zone_id = event.value;
    formData.zone = event.label;
    this.setState({
      formData: formData
    });
  }

  handleTransportChange = event => {
    let formData = this.state.formData;
    if (event) {
      formData.transport_type_id = event.value;
      formData.transport_type = event.label;
      this.setState({
        formData: formData,
        transportTypeLabel: event.label,
        transportTypeId: event.value
      });
    } else {
      formData.transport_type_id = null;
      formData.transport_type = '';
      this.setState({
        formData: formData,
        transportTypeLabel: '',
        transportTypeId: null
      });
    }
  }

  handleConvertDoOrder = () => {
    deliveryOrderApi.convertDO(this.state.dataId)
      .then(data => {
        this.setState({
          isLoading: false,
        });
        //console.log("data", data);
        apiUtil.toast('Converted to DO!!!', 'success');
        console.log('Converted to DO');
        this.props.history.push('../picking-order/all');
      });
  }

  exportItems = async () => {
    await deliveryOrderApi.exportItemXLS(this.state.dataId).then(result => {
      apiUtil.parseResult(
        result,
        (response) => {
          apiUtil.saveToFile(
            response,
            'PO Items - ' + this.state.selectedCustomer.name + '.xls'
          );

          this.setState({
            isLoading: false,
          });
        },
        (error, type) => {
          this.setState({
            isLoading: false,
          });
        }
      );
    }).catch(error => console.log(error));
  }

  getPOTypes = () => {
    deliveryOrderApi.poTypes()
      .then(data => {
        if (data.status === 200) {
          let poTypes = [];
          data.data.forEach((el) => {
            poTypes.push({
              value: el.id,
              label: el.name
            })
          })

          this.setState({
            isLoading: false,
            poTypes: poTypes
          });
        }
      });
  }

  getPOCategories = () => {
    deliveryOrderApi.orderCategories()
      .then(data => {
        if (data.status === 200) {
          let poCategories = [];
          data.data.forEach((el) => {
            poCategories.push({
              value: el.id,
              label: el.name
            })
          })

          this.setState({
            isLoading: false,
            poCategories: poCategories
          });
        }
      });
  }

  getTransportTypes = () => {
    deliveryOrderApi.transportTypes()
      .then(response => {
        if (response.status === 200) {
          let transportTypes = [];
          response.data.forEach((el) => {
            transportTypes.push({
              value: el.id,
              label: el.name
            })
          })

          this.setState({
            isLoading: false,
            transportTypes: transportTypes
          });
        }
      });
  }

  handleSubmit = (values, redirectToList, updateCallback) => {
    //console.log('submit');
    //console.log(redirectToList);
    if (this.state.order_details.length <= 0) {
      apiUtil.toast('No items in this order.', 'error');
      return false;
    }

    if (this.state.orderType == 'PickingOrder') {
      values.orderType = 'PickingOrder';
    }

    if (this.state.selectedCustomer.id === "") {
      apiUtil.toast('No customer is selected.', 'error');
      return false;
    }

    values.order_details = this.state.order_details.filter(function (el) {
      return el.quantity != null;
    });

    if (values.order_details.length <= 0) {
      apiUtil.toast('No items in this order.', 'error');
      return false;
    }

    let itemDetails = this.state.order_details;
    let anyDuplicates = false;
    for (let i = 0; i < itemDetails.length; i++) {
      itemDetails[i].is_duplicated = null;
      for (let j = 0; j < itemDetails.length; j++) {
        if (i != j && !apiUtil.isNullOrWhitespace(itemDetails[i].serial_no)
          && !apiUtil.isNullOrWhitespace(itemDetails[j].serial_no)
          && itemDetails[i].serial_no == itemDetails[j].serial_no) {
          itemDetails[i].is_duplicated = true;
          itemDetails[j].is_duplicated = true;
          anyDuplicates = true;
        }
      }
    }

    if (anyDuplicates) {
      this.setState({
        order_details: itemDetails
      });
      apiUtil.toast('Serial number duplicated.', 'error');
      return false;
    }

    let ordersDeleted = this.state.order_details_deleted;
    if (ordersDeleted.length > 0) {
      for (let i = 0; i < ordersDeleted.length; i++) {
        values.order_details.push(ordersDeleted[i]);
      }
    }

    values.order_details.forEach((item, index) => {
      //console.log(item);
      item.parent_id = item.task == "delete" && item.sale_order ? item.sale_order.id : item.id;
      item.child_id = item.id;
      item.sequence = item.task != "delete" ? index : 0;
      if (!item.task) {
        item.task = 'update';
      }
      values.order_details[index] = item;
    });

    values.customer_id = this.state.selectedCustomer.id;
    values.order_branch_id = 1;

    let formData = this.state.formData;
    for (const prop in formData) {
      if (values[prop] === undefined) {
        values[prop] = formData[prop];
      }
    }

    values.handling_out_date = this.state.formData.handling_out_date ? moment(this.state.formData.handling_out_date).format('DD-MM-YYYY') : null;
    values.is_delivery_man_power = true;

    values.handling_in = false;
    // console.log("values", values);
    // console.log("this.state.orderType", this.state.orderType);
    // return
    if (this.state.dataId === "") { // create
      this.setState({
        isLoading: true
      });

      deliveryOrderApi.create(values)
        .then(data => {
          this.setState({
            isLoading: false,
          });

          if (data.status === 200) {
            if (this.state.orderType == 'PickingOrder') {
              apiUtil.toast('Picking order created.', 'success');
              this.props.history.push('../picking-order/all');
            } else {
              apiUtil.toast('Delivery order created.', 'success');
              this.props.history.push('../delivery-order/all');
            }

          } else {
            apiUtil.toast(data.errorMessage, 'error');
          }
        })
        .catch(error => apiUtil.toast(error.message, 'error'));
    } else { // update
      //delete values.end_user_name;
      values.id = this.state.dataId;
      this.setState({
        isLoading: true
      });

      deliveryOrderApi.update(this.state.dataId, values).then(data => {
        if (!updateCallback) {
          this.setState({
            isLoading: false,
          });
        }

        if (data.status === 200) {
          if (this.state.orderType == 'PickingOrder') {
            apiUtil.toast("Picking order updated.", 'success');
            //this.props.history.push('../picking-order/all?customer=' + this.state.selectedCustomer.id);

            if (updateCallback) {
              updateCallback();
            } else {
              if (!redirectToList) {
                this.props.history.push('../picking-order/all');
              } else {
                window.location.reload();
              }
            }
          } else {
            apiUtil.toast("Delivery order updated.", 'success');
            //this.props.history.push('../delivery-order/all?customer=' + this.state.selectedCustomer.id);

            if (updateCallback) {
              updateCallback();
            } else {
              if (!redirectToList) {
                this.props.history.push('../delivery-order/all');
              } else {
                window.location.reload();
              }
            }
          }

        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      });
    }
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.handling_out_date = date;
    this.setState({
      formData: formData
    });
  }

  openItemDialog = () => {
    this.setState({
      addModal: true
    });
  }

  addItemDialog = () => {
    return <AddItemDialog
      modal={this.state.addModal}
      customer={this.state.selectedCustomer.id}
      orderDetails={this.state.order_details}
      addItem={(item) => {
        //console.log("additem", item)
        let exists = false;
        let orders = this.state.order_details;
        // orders.forEach((orderItem, i) => {
        //   if (orderItem.task == 'new' && item.id === orderItem.id) {
        //     if (orderItem.task == 'delete') {
        //       delete item.task;
        //       orders.splice(i, 1);
        //     } else {
        //       exists = true;
        //     }
        //     return false;
        //   } else if (orderItem.sale_order && orderItem.sale_order.id == item.id) {
        //     if (orderItem.task == 'delete') {
        //       delete item.task;
        //       orders.splice(i, 1);
        //     } else {
        //       exists = true;
        //     }
        //     return false;
        //   }
        // });

        // if (exists) {
        //   apiUtil.toast('Item already added.', 'info');
        //   return false;
        // }

        const newItem = {
          application_company_id: item.application_company_id,
          balance: item.balance,
          balance_till_current_date: item.balance_till_current_date,
          cbm_balance: item.cbm_balance,
          cbm_per_quantity: item.cbm_per_quantity,
          created_at: item.created_at,
          customer_id: item.customer_id,
          description: item.description,
          division: item.division,
          do_number: item.do_number,
          expiry_date: item.expiry_date,
          handling_in: item.handling_in,
          height: item.height,
          id: item.id,
          last_email_alert_sent: item.last_email_alert_sent,
          length: item.length,
          location: item.location,
          lot_no: item.lot_no,
          minimum_quantity: item.minimum_quantity,
          order_id: item.order_id,
          parent_id: item.parent_id,
          part_no: item.part_no,
          quantity: parseFloat(item.inputQuantity),
          quantity_scnaned: item.quantity_scnaned,
          remarks: item.remarks,
          sequence: item.sequence,
          serial_no: item.serial_no.trim(),
          so_number: item.so_number,
          task: 'new',
          total_cbm: item.total_cbm,
          is_duplicated: false,
          updated_at: item.updated_at,
          volume: this.calculateVolume(item.width, item.length, item.height),
          width: item.width
        };

        //console.log('add item:', newItem);
        if (this.state.formData.item_grouping === 'split_po') {
          let quantity = parseFloat(item.inputQuantity);
          for (let i = 0; i < quantity; i++) {
            let splitItem = {
              application_company_id: item.application_company_id,
              balance: item.balance,
              balance_till_current_date: item.balance_till_current_date,
              cbm_balance: item.cbm_balance,
              cbm_per_quantity: item.cbm_per_quantity,
              created_at: item.created_at,
              customer_id: item.customer_id,
              description: item.description,
              division: item.division,
              do_number: item.do_number,
              expiry_date: item.expiry_date,
              handling_in: item.handling_in,
              height: item.height,
              id: item.id,
              last_email_alert_sent: item.last_email_alert_sent,
              length: item.length,
              location: item.location,
              lot_no: item.lot_no,
              minimum_quantity: item.minimum_quantity,
              order_id: item.order_id,
              parent_id: item.parent_id,
              part_no: item.part_no,
              quantity: 1,
              quantity_scnaned: item.quantity_scnaned,
              remarks: item.remarks,
              sequence: item.sequence,
              serial_no: item.serial_no.trim(),
              so_number: item.so_number,
              task: 'new',
              total_cbm: item.total_cbm,
              is_duplicated: false,
              updated_at: item.updated_at,
              volume: this.calculateVolume(item.width, item.length, item.height),
              width: item.width
            };
            orders.push(splitItem);
          }
        } else {
          orders.push(newItem);
        }

        //console.log(orders);

        this.setState({
          order_details: orders
        });

        this.calculateVolumeGrandTotal();
        this.calculateQtyTotal();
        apiUtil.toast('Item added', 'success');
      }}
      onClose={(data) => {
        this.setState({
          addModal: false
        });
      }}
    />
  }

  editItemDialog = () => {
    return <EditItemDialog
      modal={this.state.editModal}
      data={this.state.editItemData}
      onUpdate={(data) => {
        let orderData = this.state.order_details;

        if (typeof data === 'object' && data !== null) {
          let index = data.index;
          delete data.index;

          data.task = 'update';
          data.volume = this.calculateVolume(data.width, data.length, data.height);
          orderData[index] = data;
        }

        this.setState({
          editModal: false,
          editItemData: null,
          order_details: orderData
        });

        this.calculateVolumeGrandTotal();
        this.calculateQtyTotal();
      }}
    />
  }

  editItemOrder = index => {
    let orderData = this.state.order_details;
    orderData[index]['index'] = index;
    orderData[index]['read_only_quantity'] = this.state.formData.item_grouping == 'split_po' ? 'readonly' : '';
    this.setState({
      editModal: true,
      editItemData: orderData[index]
    });
  }

  orderItemUpdate = (name, el) => {
    let orderItemData = this.state.orderItemData;
    orderItemData[name] = el;

    this.setState({
      orderItemData: orderItemData
    });
  }

  addItemToOrder = (item) => {
    if (Array.isArray(item)) {
      item.forEach(i => {
        i.volume = this.calculateVolume(i.width, i.length, i.height);
      });

      this.setState({
        order_details: item
      });
    } else {
      let orders = this.state.order_details;
      item.existing = false;
      item.volume = this.calculateVolume(item.width, item.length, item.height);
      orders.push(item);
      this.setState({
        order_details: orders
      });
    }

    this.calculateVolumeGrandTotal();
    this.calculateQtyTotal();
  }

  deleteItemOrder = index => {
    let orders = this.state.order_details;
    let ordersDeleted = this.state.order_details_deleted;

    if (orders[index]['id'] && orders[index]['task'] != 'new' && this.state.dataId != "") {
      orders[index]['task'] = 'delete';
      ordersDeleted.push(orders[index]);
      orders.splice(index, 1);
    } else {
      orders.splice(index, 1);
      //delete orders[index];
    }

    this.setState({
      order_details: orders,
      order_details_deleted: ordersDeleted
    }, () => {
      this.calculateVolumeGrandTotal();
      this.calculateQtyTotal();
    });
  }

  openRemoveAllDialog = () => {
    this.setState({
      removeAllModal: true
    });
  }

  removeAllDialog = () => {
    return <RemoveDialog
      modal={this.state.removeAllModal}
      onRemove={(isRemove) => {
        if (isRemove) {
          this.deleteAllItem();
        }

        this.setState({
          removeAllModal: false
        });
      }}
    />
  }

  deleteAllItem = () => {
    let orders = this.state.order_details;
    let ordersDeleted = this.state.order_details_deleted;

    if (orders.length > 0) {
      for (let idx = 0; idx < orders.length; idx++) {
        if (orders[idx]['id'] && orders[idx]['task'] != 'new' && this.state.dataId != "") {
          orders[idx]['task'] = 'delete';
          ordersDeleted.push(orders[idx]);
        }
      }
    }

    orders = [];

    this.calculateVolumeGrandTotal();
    this.calculateQtyTotal();

    this.setState({
      order_details: orders
    });
  }

  toggleImportModal = show => {
    this.setState({
      importModal: true
    });
  }

  importDialog = () => {
    return <ImportItemDialog
      modal={this.state.importModal}
      onImport={async (data) => {
        if (data) {
          let orderData = this.state.order_details;

          this.setState({
            isLoading: true
          });

          let error = false;
          for (let i = 0; i < data.length; i++) {
            if (data[i].part_no) {
              orderData[i].serial_no = data[i].serial_no;
              orderData[i].task = 'update';
            }
          }
          this.setState({
            isLoading: false,
            editItemData: null,
            order_details: orderData
          });
        }

        this.setState({
          importModal: false
        });
      }}
      onClose={() => {
        this.setState({
          importModal: false
        });
      }}
    />
  }

  calculateVolume = (width, length, height) => {
    let val = (parseFloat(width) * parseFloat(length) * parseFloat(height) / 1000000);
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6 ? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeTotal = (volume, qty) => {
    let val = (parseFloat(volume) * parseFloat(qty));
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6 ? parseFloat(val.toFixed(6)) : val;
  }

  calculateVolumeGrandTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      if (item.task != 'delete') {
        result += (parseFloat(item.volume) * parseFloat(item.quantity));
      }
    });

    let splitter = result.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6 ? parseFloat(result.toFixed(6)) : result;
  }

  calculateQtyTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      if (item.task != 'delete') {
        result += parseFloat(item.quantity);
      }
    });

    return result;
  }

  getData = (dataId) => {
    deliveryOrderApi.show(dataId).then(data => {
      if (data.status === 200) {
        this.populateData(data.data.result);
      } else {
        apiUtil.toast(data.message, 'error');
      }
    });
  }

  populateData = (data) => {
    let formData = this.state.formData;
    //console.log('populate', formData, data);
    for (const prop in formData) {
      if (data[prop] !== undefined) {
        if (prop === 'handling_out_date') {
          formData.initial_handling_out_date = data.handling_out_date ? new Date(data.handling_out_date) : null;
          formData.handling_out_date = data.handling_out_date ? new Date(data.handling_out_date) : null;
        } else {
          formData[prop] = data[prop];
        }
      }
    }

    if (data.end_user !== undefined) {
      //formData.end_user_id = data.end_user.id;
      formData.end_user_name = data.end_user.end_user;
      formData.end_user_contact_no = data.end_user.contact_no;
    }

    if (data.zone_id) { formData.zone_id = data.zone_id; formData.zone = data.zone.zone_name; }
    if (data.transport_type_id) {
      formData.transport_type_id = data.transport_type_id;
      this.state.transportTypes.map(function (item, index) {
        if (item.value == data.transport_type_id) { formData.transport_type = item.label; }
      });

      this.setState({
        transportTypeLabel: formData.transport_type,
        transportTypeId: data.transport_type_id
      });
    }

    if (data.department_id) { formData.department_id = data.department_id; }
    if (data.customer_address_id) {
      formData.customer_address_id = data.customer_address_id;
    }

    let orderDetails = data.order_details;

    if (orderDetails && orderDetails.length > 0) {
      orderDetails.sort((a, b) => a.sequence - b.sequence);
    }

    if (this.state.poTypes.length <= 0 && formData.po_type) {
      let poTypes = [];
      poTypes.push({
        value: formData.po_type.id,
        label: formData.po_type.name
      })

      this.setState({
        isLoading: false,
        poTypes: poTypes
      });
    }

    this.setState({
      pageLoading: false,
      formData: formData,
      order_details: orderDetails
    });

    this.handleCustomerChange({ value: data.customer.id, name: data.customer.company_name, label: data.customer.company_code });
  }

  pageTitle = () => this.state.orderType === 'PickingOrder' ? " Picking " : " Delivery ";

  render() {
    const theClass = this;
    //console.log("this.state.formData", this.state.formData)

    const columns = [
      {
        name: "id",
        label: "No.",
        options: {
          customBodyRender: (value, data) => {
            let rowNumber = data.rowIndex + 1;

            return rowNumber;
          }
        }
      },
      {
        name: "description",
        label: "Description"
      },
      {
        name: "volume",
        label: "Vol (m3)",
        options: {
          setCellProps: () => ({ className: "hide-mobile" }),
        }
      },
      {
        name: "part_no",
        label: "Part No."
      },
      {
        name: "lot_no",
        label: "Lot No.",
        options: {
          setCellProps: () => ({ className: "hide-mobile" }),
        }
      },
      {
        name: "serial_no",
        label: "Serial No.",
        options: {
          setCellProps: () => ({ style: { minWidth: "150px", maxWidth: "100%" } }),
          customBodyRender: (value, data) => {
            let rowData = this.state.order_details[data.rowIndex];

            return <Input className="form-control max-mobile"
              value={value}
              onChange={(e) => theClass.handleSerialNoChange(e, data.rowIndex)}
              onKeyPress={(e) => theClass.handleSerialNoKeyPress(e)}
              invalid={value && rowData.is_duplicated ? rowData.is_duplicated : null} />;
          }
        }
      },
      {
        name: "quantity",
        label: "Qty"
      },
      {
        name: "division",
        label: "Division",
        options: {
          setCellProps: () => ({ className: "hide-mobile" }),
        }
      },
      {
        name: "location",
        label: "Location"
      },
      {
        name: "remarks",
        label: "Remarks"
      },
      {
        name: "expiry_date",
        label: "Expiry Date",
        options: {
          setCellProps: () => ({ className: "hide-mobile" }),
          customBodyRender: (value, data) => {
            return value ? moment(value).format('DD-MM-YYYY') : '';
          }
        }
      },
      {
        name: "volume",
        label: "Total Vol (m3)",
        options: {
          setCellProps: () => ({ className: "hide-mobile" }),
          customBodyRender: (value, data) => {
            return theClass.calculateVolumeTotal(data.rowData[2], data.rowData[6]);
          }
        }
      },
      {
        name: "id",
        label: "Action",
        display: theClass.state.readOnly ? false : true,
        options: {
          setCellProps: () => ({ className: "hide-mobile" }),
          customBodyRender: (id, data) => {
            if (apiUtil.checkAccess("PickingOrderUpdate")) {
              return <ButtonGroup>
                <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(data.rowIndex)} />
                <button title="Delete" type="button" className="btn-icon" onClick={e => theClass.deleteItemOrder(data.rowIndex)}>
                  <DeleteIcon />
                </button>
              </ButtonGroup>;
            }
            return "";
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 1000,
      rowsPerPageOptions: [1000],
      serverSide: false,
      sort: false,
      download: false,
      print: false,
      search: false,
      viewColumns: false,
      filter: false,
      pagination: false
    }

    return (
      <Box className="dashboard-page">
        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? apiUtil.checkAccess("PickingOrderUpdate") ? "Update " + this.pageTitle() + " Order" : "View " + this.pageTitle() + " Order" : "Create " + this.pageTitle() + " Order"}</h1>
        </StickyPageHeader>

        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              container_receipt: this.state.formData.container_receipt,
              hawb: this.state.formData.hawb,
              end_user_name: this.state.formData.end_user_name,
              end_user_contact_no: this.state.formData.end_user_contact_no,
              total_kg: this.state.formData.total_kg,
              total_package: this.state.formData.total_package,
              delivery_to: this.state.formData.delivery_to,
              remarks: this.state.formData.remarks
            }}
            validationSchema={deliveryOrderSchema}
            onSubmit={values => { this.handleSubmit(values); }}>
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              validateForm
            }) => (
              <Form>
                <div className={this.state.readOnly ? "overlay" : ""}>
                  <Col md={{ size: 12 }}>
                    <Card>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col xs="12">
                              <h4 className="font-weight-bold mb-4">Company</h4>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12">
                              <div className="mb-4">
                                <Label className="control-label">
                                  Choose Company Code*
                                </Label>
                                <Select value={{ label: this.state.selectedCustomer.code, value: this.state.selectedCustomer.id }} className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                              </div>

                              <div className="mb-4">
                                <Label className="control-label">
                                  Company Name
                                </Label>
                                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
                              </div>
                            </Col>
                          </Row>
                          {/* <Row className="mb-2">
                          <Col xs="12" className="mt-3">
                            <Label className="control-label">
                              CR/PO/SO
                            </Label>
                            <Field className="form-control form-input" name="container_receipt" value={this.state.formData.container_receipt || ''} onChange={this.handleInputChange} />
                            {
                              errors.container_receipt && touched.container_receipt ? (
                                <div className="invalid-feedback d-block">
                                  {errors.container_receipt}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row> */}

                          {/* <Row className="mb-2">
                          <Col xs="12" className="mt-3">
                            <Label className="control-label">
                              Branch Name
                            </Label>
                            <Select className="form-input no-border" value={{ label: this.state.formData.branchName, value: this.state.formData.customer_address_id }} options={this.state.branchList} onChange={(e) => this.customerBranchNameChange(e)} />
                          </Col>
                        </Row> */}

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Company Address
                              </Label>
                              <Field className="form-control form-input" name="branchAddress" value={this.state.formData.branchAddress || ''} onChange={this.handleInputChange} />
                              {
                                errors.branchAddress && touched.branchAddress ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.branchAddress}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          {<Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Office Phone
                              </Label>
                              <Field className="form-control form-input" name="delivery_contact_no" value={this.state.formData.delivery_contact_no || ''} onChange={this.handleInputChange} />
                              {
                                errors.delivery_contact_no && touched.delivery_contact_no ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.delivery_contact_no}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>}

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Department
                              </Label>
                              <Select className="form-input no-border" value={{ label: this.state.formData.department, value: this.state.formData.department_id }} options={this.state.departmentList} onChange={(e) => this.customerDepartmentChange(e)} />
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Person In Charge
                              </Label>

                              {this.state.attentionList.map(function (item, index) {
                                let picDisplay = item.label;

                                if (item.contact.email) {
                                  picDisplay = item.label + ' - ' + item.contact.email;
                                }

                                return <Input className="form-control form-input" defaultValue={picDisplay} disabled="" readOnly="readonly" />
                              })}
                              {/* <Select value={{ label: this.state.formData.delivery_attention, value: this.state.formData.delivery_attention }} className="form-input no-border" options={this.state.attentionList} onChange={(e) => this.customerAttentionChange(e)} />
                            <Input className="form-control form-input" defaultValue={this.state.formData.delivery_contact ? this.state.formData.delivery_contact.contact_no : ''} disabled="" readOnly="readonly" />
                            <Input className="form-control form-input" defaultValue={this.state.formData.delivery_contact ? this.state.formData.delivery_contact.email : ''} disabled="" readOnly="readonly" /> */}
                            </Col>
                          </Row>


                          <Row>
                            <Col xs="12">
                              <h4 className="font-weight-bold mb-4">Delivery Info</h4>
                            </Col>
                          </Row>
                          {/* <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Location
                          </Label>
                          <Select className="form-input no-border" options={zoneData} value={{label: this.state.formData.zone, value: this.state.formData.zone_id}} onChange={(e) => this.handleZoneChange(e)} />
                        </Col>
                      </Row> */}

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Document No
                              </Label>
                              <Field className="form-control form-input" name="document_no" value={this.state.formData.document_no || ''} onChange={this.handleInputChange} />
                              {
                                errors.document_no && touched.document_no ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.document_no}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Delivery Address
                              </Label>
                              <Field className="form-control form-input" name="delivery_address_line1" value={this.state.formData.delivery_address_line1 || ''} onChange={this.handleInputChange} />
                              {
                                errors.delivery_address_line1 && touched.delivery_address_line1 ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.delivery_address_line1}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Delivery Order Reference
                              </Label>
                              <Field readOnly className="form-control form-input" name="do_number" value={this.state.formData.do_number || ''} onChange={this.handleInputChange} />
                              {
                                errors.do_id && touched.do_id ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.do_id}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Delivery Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.handling_out_date} onChange={date => this.changeDate(date)} isClearable />
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Delivery Timing
                              </Label>
                              <Field className="form-control form-input" name="delivery_timing" value={this.state.formData.delivery_timing || ''} onChange={this.handleInputChange} />
                              {
                                errors.delivery_timing && touched.delivery_timing ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.delivery_timing}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                End User
                              </Label>
                              <Field className="form-control form-input" name="end_user_name" value={this.state.formData.end_user_name || ''} onChange={this.handleInputChange} />
                              {
                                errors.end_user_name && touched.end_user_name ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.end_user_name}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                End User Contact No
                              </Label>
                              <Field className="form-control form-input" name="end_user_contact_no" value={this.state.formData.end_user_contact_no || ''} onChange={this.handleInputChange} />
                              {
                                errors.end_user_contact_no && touched.end_user_contact_no ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.end_user_contact_no}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Remarks
                              </Label>
                              <Field className="form-control form-input" name="remarks" value={this.state.formData.remarks || ''} onChange={this.handleInputChange} />
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12">
                              <div className="mb-4">
                                <Label className="control-label">
                                  Order Types
                                </Label>
                                <Select value={{ label: this.state.poTypeLabel, value: this.state.po_type_id }} className="form-input has-margin no-border" options={this.state.poTypes} isClearable={true} onChange={(e) => this.handlePoType(e)} />
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12">
                              <div className="mb-4">
                                <Label className="control-label">
                                  Category
                                </Label>
                                <Select value={{ label: this.state.orderCategoryLabel, value: this.state.order_category_id }} className="form-input has-margin no-border" options={this.state.poCategories} isClearable={true} onChange={(e) => this.handlePoCategory(e)} />
                              </div>
                            </Col>
                          </Row>
                          {/* <Row className="mb-2">
                          <Col xs="12" className="mt-3">
                            <Label className="control-label">
                              Recipient
                            </Label>
                            <Field className="form-control form-input" name="delivery_pic" value={this.state.formData.delivery_pic || ''} onChange={this.handleInputChange} /> */}
                          {/*<Select value={{label: this.state.formData.delivery_by, value: this.state.formData.delivery_by}} className="form-input no-border" options={this.state.attentionList} onChange={(e) => this.customerDropOffChange(e)} />*/}
                          {/* <MuiThemeProvider theme={theme}>
                            <Autocomplete freeSolo className="form-input" 
                                options={this.state.attentionList.map((option) => option.label)}
                                onChange={(e, v) => this.customerDropOffChange(e,v)}
                                value={this.state.formData.delivery_by}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" className="form-control form-input no-border" onChange={(e) => this.customerDropOffChange(e,null)}/>
                                )}
                            />
                          </MuiThemeProvider>  */}
                          {/* </Col>
                        </Row> */}

                          {/* <Row className="mb-2">
                          <Col xs="12" className="mt-3">
                            <Label className="control-label">
                              Recipient Phone No
                            </Label>
                            <Field className="form-control form-input" name="delivery_contact_no" value={this.state.formData.delivery_contact_no || ''} onChange={this.handleInputChange} />
                            {
                              errors.delivery_contact_no && touched.delivery_contact_no ? (
                                <div className="invalid-feedback d-block">
                                  {errors.delivery_contact_no}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row> */}
                          <Row>
                            <Col xs="12">
                              <h4 className="font-weight-bold mb-4">Item</h4>
                            </Col>
                          </Row>
                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                HAWB
                              </Label>
                              <Field className="form-control form-input" name="hawb" value={this.state.formData.hawb || ''} onChange={this.handleInputChange} />
                              {
                                errors.hawb && touched.hawb ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.hawb}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Total Weight (kg)
                              </Label>
                              <Field className="form-control form-input" type="number" name="total_kg" value={this.state.formData.total_kg || ''} onChange={this.handleInputChange} placeholder="kg" />
                              {
                                errors.total_kg && touched.total_kg ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.total_kg}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Total Packages
                              </Label>
                              <Field className="form-control form-input" type="text" name="total_package" value={this.state.formData.total_package || ''} onChange={this.handleInputChange} />
                              {
                                errors.total_package && touched.total_package ? (
                                  <div className="invalid-feedback d-block">
                                    {errors.total_package}
                                  </div>
                                ) : null
                              }
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                Transport Type
                              </Label>
                              <Select className="form-input no-border" options={this.state.transportTypes}
                                onChange={this.handleTransportChange}
                                value={{ label: this.state.transportTypeLabel, value: this.state.transportTypeId }}
                                isClearable={true}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }} />
                            </Col>
                          </Row>

                          <Row className="mb-2">
                            <Col xs="12" className="mt-3">
                              <Label className="control-label">
                                ManPower
                              </Label>
                              <Field type="radio" name="delivery_man_power" value="1" id="delivery_man_power" checked={this.state.formData.delivery_man_power == '1'} onChange={this.handleInputChange} />
                              <Label className="form-check-label ml-1 mr-3" for="delivery_man_power_yes">Yes</Label>
                              <Field type="radio" name="delivery_man_power" value="0" id="delivery_man_power" checked={this.state.formData.delivery_man_power == '0'} onChange={this.handleInputChange} />
                              <Label className="form-check-label ml-1 mr-3" for="delivery_man_power_no">No</Label>

                              {/* <Field className="form-control form-input" type="radio" name="delivery_man_power" value={this.state.formData.delivery_man_power || ''} onChange={this.handleInputChange} />
                          {
                            errors.delivery_man_power && touched.delivery_man_power ? (
                              <div className="invalid-feedback d-block">
                              {errors.delivery_man_power}
                              </div>
                              ) : null
                          } */}
                            </Col>
                          </Row>
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </div>

                <div>
                  <Col md={{ size: 12 }} className="px-0 px-md-3">
                    <Card className="mt-2 px-0 px-md-3">
                      <CardBody className="px-0">
                        <Col xs="12">
                          <h4 className="font-weight-bold mb-4">Items in this Order</h4>
                        </Col>
                        <Col md="12" className="px-0 px-md-3">
                          <MuiThemeProvider theme={TableTheme}>
                            <MUIDataTable
                              data={this.state.order_details.filter(item => item.task === undefined || item.task !== 'delete')}
                              columns={columns}
                              options={options}
                            />
                          </MuiThemeProvider>
                        </Col>
                        <div className="table-responsive">
                          <Table className="mb-5">
                            {/* <thead>
                              <tr>
                                <th>No.</th>
                                <th>Description</th>
                                <th>Vol(m<sup>3</sup>)</th>
                                <th>Part No.</th>
                                <th>Lot No.</th>
                                <th>Serial No.</th>
                                <th>Qty</th>
                                <th>Division</th>
                                <th>Location</th>
                                <th>Remarks</th>
                                <th>Expire Date</th>
                                <th>Total Vol(m<sup>3</sup>)</th>
                                <th className={apiUtil.checkAccess("PickingOrderUpdate") ? "" : "d-none"}>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {this.state.order_details.map(function (item, index) {
                                if (item.task === undefined || item.task != 'delete') {
                                  return <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.description}</td>
                                    <td>{item.volume}</td>
                                    <td>{item.part_no}</td>
                                    <td>{item.lot_no}</td>
                                    <td className="px-0">
                                      <Input className="form-control form-input"
                                        defaultValue={item.serial_no}
                                        onChange={(e) => theClass.handleSerialNoChange(e, index)}
                                        onKeyPress={(e) => theClass.handleSerialNoKeyPress(e)} />
                                    </td>
                                    <td>{item.quantity}</td>
                                    <td>{item.division}</td>
                                    <td>{item.location}</td>
                                    <td>{item.remarks}</td>
                                    <td>{item.expiry_date ? moment(item.expiry_date).format('DD-MM-YYYY') : ""}</td>
                                    <td>{theClass.calculateVolumeTotal(item.volume, item.quantity)}</td>
                                    <td className={apiUtil.checkAccess("PickingOrderUpdate") ? "" : "d-none"}>
                                      <ButtonGroup>
                                        {<Button size="sm" color="info" onClick={e => theClass.editItemOrder(index)}>Edit</Button>}
                                        <Button size="sm" color="danger" onClick={e => theClass.deleteItemOrder(index)}>Delete</Button>
                                      </ButtonGroup>
                                    </td>
                                  </tr>
                                } else return "";
                              })}
                            </tbody> */}
                            <tfoot>

                              <tr>
                                <th colSpan='6' style={{ textAlign: 'right' }}>Total Qty</th>
                                <th>{theClass.calculateQtyTotal()}</th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th style={{ textAlign: 'right' }}>Grand Total Vol (m<sup>3</sup>)</th>
                                <th colSpan='2'>{theClass.calculateVolumeGrandTotal()}</th>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </CardBody>

                      {/* <CardFooter>
                        {this.state.dataId === "" ? <Button outline={true} onClick={(e) => this.openItemDialog()} color="success">Add Item</Button> : <div></div> }                        
                      </CardFooter> */}
                      <CardFooter>
                        {!this.state.readOnly && <Button outline={true} onClick={(e) => this.openItemDialog()} color="success">Add Item</Button>}
                        {this.state.dataId !== '' && <Button outline={true} onClick={(e) => this.exportItems()} color="info" className="ml-3">Export Items</Button>}
                        {this.state.dataId !== '' && <Button
                          className="btn-multiple-state ml-3"
                          color="info"
                          outline={true}
                          type="button"
                          disabled={this.state.isLoading}
                          onClick={() => this.toggleImportModal(true)}>
                          Import from Excel
                          {this.state.isLoading &&
                            <CircularProgress size={20} />} </Button>}
                        {!this.state.readOnly && <Button outline={true} onClick={(e) => this.openRemoveAllDialog()} color="danger" className="float-right">Remove All Items</Button>}
                      </CardFooter>
                    </Card>
                    <div className="d-flex justify-content-between">
                      <Card>
                        {(!this.state.readOnly
                          && !((this.state.formData.do_number && this.state.formData.do_number != '') || this.state.dataId == '' || !this.state.formData.initial_handling_out_date)
                        ) && <ProgressButton
                          btnClassName={'btn btn-outline-success'}
                          onClick={() => {
                            this.handleSubmit(values, false, this.handleConvertDoOrder);
                          }}
                          type={'button'}
                          color="success"
                          isLoading={this.state.isLoading}>
                            Convert To Delivery Order
                          </ProgressButton>}
                      </Card>
                      <ButtonGroup>
                        {(!this.state.readOnly || this.state.canSave) && <Card>
                          <ProgressButton
                            type={'submit'}
                            color={'secondary'}
                            isLoading={this.state.isLoading}>
                            <Box pl={2} pr={2}>Submit and Back</Box>
                          </ProgressButton>
                        </Card>}
                        {((!this.state.readOnly || this.state.canSave) && this.state.dataId) && <Card>
                          <ProgressButton
                            type={'submit'}
                            color={'secondary'}
                            isLoading={this.state.isLoading}
                            onClick={() => this.handleSubmit(values, true)}
                          >
                            <Box pl={2} pr={2}>Submit</Box>
                          </ProgressButton>
                        </Card>}
                        {this.state.dataId &&
                          <Card>
                          </Card>}
                      </ButtonGroup>
                    </div>
                  </Col>
                </div>
              </Form>
            )}
          </Formik>
        </Row>

        {this.addItemDialog()}
        {this.editItemDialog()}
        {this.removeAllDialog()}
        {this.importDialog()}
      </Box>
    );
  }
}

export default PickingOrderForm;