import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import VehicleList from "./list";
import Vehicle from "./form";
import VehicleChecklistForm from "./formchecklist";
import VehicleForkliftChecklistForm from "./formForkliftChecklist";

const Vehicles = ({ match }) => {
  let scope = localStorage.getItem("scope");

  if (scope === 'customer_admin') {
    return (
      <Redirect to="/transaction/sales-order" />
      )
  }

  return (
    <Switch>
      <Route
        path={`${match.url}/all-vehicles`}
        render={props => <VehicleList {...props} />}
      />
      <Route
        path={`${match.url}/create`}
        render={props => <Vehicle {...props} />}
      />
      <Route
        path={`${match.url}/edit`}
        render={props => <Vehicle {...props} />}
      />
      <Route
        path={`${match.url}/create-vehicle-checklist`}
        render={props => <VehicleChecklistForm {...props} />}
      />
      <Route
        path={`${match.url}/create-forklift-checklist`}
        render={props => <VehicleForkliftChecklistForm {...props} />}
      />
      <Redirect to="/vehicles/all-vehicles" />
    </Switch>
  )
};

export default Vehicles;
