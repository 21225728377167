import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Row, Label, Form, FormGroup, Col, Input } from "reactstrap";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";

import Select from 'react-select';
import apiUtil from '../../api/apiUtil';
import reportApi from "../../api/Report";
import customerApi from "../../api/Customer";

import moment from "moment";
import DatePicker from "react-datepicker";
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker';
import "react-datepicker/dist/react-datepicker.css";

import HandlingReport from "./Partial/HandlingReport";
import RentalReport from "./Partial/RentalReport";

class InOutReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDate: new Date(),
      // dateRange: {
      //   startDate: moment(),
      //   endDate: moment(),
      // },
      filter: 'handling_fee',
      isLoading: false,
      customerList: [],
      handlingReportData: null,
      rentalReportData: null,
      selectedCustomer: {
        id: 0,
        name: ''
      },
      hideCustomerFilter: false
    };
  }

  componentDidMount = () => {
    document.title = "In Out Report | WMS";
    window.scrollTo(0, 0);

    let customer = JSON.parse(localStorage.getItem("customer"));

    if (customer) {
      this.setState({
        selectedCustomer: {
          id: customer.id,
          name: customer.company_name,
          label: customer.company_code
        },
        hideCustomerFilter: true
      });
    } else {
      this.getCustomer();
    }
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  handleCustomerChange = event => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name
      }
    });
  }

  handleFilter = event => {
    this.setState({
      filter: event.target.value
    });
  }

  changeDate = date => {
    this.setState({
      displayDate: date
    });
  }

  generateReport = async (event) => {
    event.preventDefault();
    if (this.state.selectedCustomer.id === 0) {
      apiUtil.toast('Select customer first!', 'error');
      return false;
    }

    this.setState({
      isLoading: true,
      handlingReportData: null,
      rentalReportData: null
    });

    let selectedDate = this.state.displayDate;
    let startDate = moment(selectedDate).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');
    console.log(this.state.dateRange);
    // let startDate = moment(this.state.dateRange.startDate).format('YYYY-MM-DD');
    // let endDate = moment(this.state.dateRange.endDate).format('YYYY-MM-DD');
    // console.log(startDate, endDate);

    await reportApi.getReport({
      start_date: startDate,
      end_date: endDate,
      customer_id: this.state.selectedCustomer.id
    }, this.state.filter).then(data => {
      if (data.errorMessage !== "") {
        apiUtil.toast(data.errorMessage, 'error');
        this.setState({
          isLoading: false,
        });
        return false;
      }

      let updatedState = {
        isLoading: false
      };

      if (this.state.filter === 'handling_fee') {
        updatedState.handlingReportData = data.data.data;
        updatedState.rentalReportData = null;
      } else if (this.state.filter === 'rental_fee') {
        updatedState.handlingReportData = null;
        updatedState.rentalReportData = data.data.data;
      }

      this.setState(updatedState);
    }).catch(error => console.log(error));
  }

  excelReport = async () => {
    this.setState({
      isLoading: true
    });

    let selectedDate = this.state.displayDate;
    let startDate = moment(selectedDate).startOf('month').format('YYYY-MM-DD');
    let endDate = moment(selectedDate).endOf('month').format('YYYY-MM-DD');

    await reportApi.exportReportXLS({
      start_date: startDate,
      end_date: endDate,
      customer_id: this.state.selectedCustomer.id
    }, this.state.filter).then(result => {
      apiUtil.parseResult(
        result,
        (response) => {
          apiUtil.saveToFile(
            response,
            this.state.filter === 'handling_fee'
              ? 'Handling Fee ' + this.state.selectedCustomer.name + '.xls'
              : 'Rental Fee ' + this.state.selectedCustomer.name + '.xls'
          );

          this.setState({
            isLoading: false,
          });
        },
        (error, type) => {
          this.setState({
            isLoading: false,
          });
        }
      );
    }).catch(error => console.log(error));
  }

  render() {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">Rental Report</h1>
        </StickyPageHeader>

        <Row className="mt-3">
          <Col md={{ size: 8, offset: 2 }}>
            <Form onSubmit={this.generateReport}>
              <FormGroup className={this.state.hideCustomerFilter ? "d-none" : "mb-4"}>
                <Label className="control-label">
                  Choose Company Code*
                </Label>
                <Select className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </FormGroup>

              <FormGroup className="mb-4 clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </FormGroup>

              <FormGroup className="mb-4 clearfix">
                <Label className="control-label">
                  Invoice Type
                </Label>
                <FormGroup check className="form-input">
                  <Label check>
                    <Input type="radio" defaultValue="handling_fee" checked={this.state.filter === 'handling_fee'} onChange={(e) => this.handleFilter(e)} /> Handling Fee
                  </Label>

                  <Label check>
                    <Input type="radio" defaultValue="rental_fee" checked={this.state.filter === 'rental_fee'} onChange={(e) => this.handleFilter(e)} />
                    Rental Fee
                  </Label>
                </FormGroup>
              </FormGroup>

              <FormGroup className="mb-4">
                <Label className="control-label">
                  Month
                </Label>
                <DatePicker className="form-control" selected={this.state.displayDate} onChange={date => this.changeDate(date)} dateFormat="MM/yyyy" showMonthYearPicker />

                {/* 
                <CustomDateRangePicker
                  className="form-input"
                  range={this.state.dateRange}
                  onChange={(range) => {
                    this.setState({
                      dateRange: range,
                    });
                  }}
                  toggle=""
                /> */}
              </FormGroup>

              <FormGroup className="mb-4">
                <Label className="control-label">{' '}</Label>
                <div className="form-input">
                  <ProgressButton
                    type={'submit'}
                    color={'secondary'}
                    isLoading={this.state.isLoading}>
                    <Box pl={2} pr={2}>Generate Report</Box>
                  </ProgressButton>
                </div>
              </FormGroup>
              {/* <FormGroup className="mb-4">
                <Label className="control-label">{' '}</Label>
                <div className="form-input">
                  <ProgressButton
                    type={'button'}
                    color={'secondary'}
                    isLoading={this.state.isLoading}
                    onClick={this.excelReport}
                  >
                    <Box pl={2} pr={2}>Excel Report</Box>
                  </ProgressButton>
                </div>
              </FormGroup> */}
            </Form>
          </Col>
        </Row>

        {this.state.handlingReportData !== null &&
          this.state.filter === "handling_fee" &&
          <HandlingReport reportData={this.state.handlingReportData} />
        }

        {this.state.rentalReportData !== null &&
          this.state.filter === "rental_fee" &&
          <RentalReport reportData={this.state.rentalReportData} onDownload={() => this.excelReport()} />
        }
      </Box>
    );
  }
}

export default InOutReport;