import React, { Component } from "react";
import { Box } from '@material-ui/core';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Label,
  ButtonGroup
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import * as Yup from "yup";
import queryString from 'query-string';
import Loading from "react-fullscreen-loading";
import ProgressButton from "../../components/Button/ProgressButton";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

import VehicleChecklist from './Partial/VehicleChecklist';

import apiUtil from "../../api/apiUtil";
import api from "../../api/api.js";

const VehicleSchema = Yup.object({
  plate_no: Yup.string().required('Value is required'),
  manufacturer: Yup.string().required('Value is required'),
  model: Yup.string().required('Value is required'),
  colour: Yup.string().required('Value is required'),
  load_capacity: Yup.number().nullable(true)
    .typeError('Value must be a number'),
  engine_capacity: Yup.number().nullable(true)
    .typeError('Value must be a number'),
});

class VehicleForm extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      dataId: '',
      errors: {},
      isLoading: false,
      pageLoading: false,
      readOnly: false,
      canSave: true,
      oldData: null,
      typeList: [],
      selectedType: {
        id: '0',
        name: '',
      },
      formData: {
        plate_no: '',
        manufacturer: '',
        model: '',
        colour: '',
        load_capacity: '',
        engine_capacity: '',
        registration_date: null,
        road_tax_expiry_date: null,
        inspection_date: null,
        vehicle_type_id: null
      }
    };

    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.populateData = this.populateData.bind(this);
  }

  componentWillMount() {
    this._isMounted = true;

    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        pageLoading: true,
        dataId: urlQuery.id
      });
      this.getType();
      this.getData(urlQuery.id);
      document.title = "View Vehicle | WMS";
    } else {
      document.title = "Create View | WMS";
      this.getType();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getData = id => {
    api.details("vehicles", id).then((result) => {
      apiUtil.parseResult(
        result,
        (data) => {
          let result = data && data.result ? data.result : null;

          if (result) {
            this.populateData(result);
          } else {
            apiUtil.toast(data.errorMessage, 'warning');
          }
        },
        (error, type) => {
          apiUtil.toast(error.message, 'error');
        }
      );
    });
  }

  getType = async () => {
    let params = {
      app_id: 1
    };

    api.read("vehicle_types", params, null, 'admin').then((data) => {
      console.log(data);
      console.log(data.data.data.result);
      if (Array.isArray(data.data.data.result)) {
        let typeList = [];
        data.data.data.result.forEach((val) => {
          typeList.push({
            value: val.id,
            label: val.name,
            name: val.name
          });
        });

        this.setState({
          typeList: typeList,
          pageLoading: false
        });
      } else {
        apiUtil.toast('Failed to get vehicle types.', 'error');
      }
    }).catch(error => console.log(error));
  }

  populateData = data => {
    let formData = this.state.formData;

    for (const key in formData) {
      if (key.includes('date') && data[key]) {
        formData[key] = new Date(data[key]);
      } else {
        formData[key] = data[key];
      }
    }

    this.setState({
      //oldData: data,
      pageLoading: false,
      formData: formData,
    });
    
    this.handleTypeChange({ value: data.vehicle_type_id, name: data.vehicle_type.name });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleSelectChange = (event, name) => {
    let formState = this.state.formData;
    formState[name] = event.value;

    this.setState({
      formData: formState
    });
  }

  changeDate = (date, name) => {
    let formData = this.state.formData;
    formData[name] = date;

    this.setState({
      formData: formData
    });
  }

  handleTypeChange = async (event) => {
    this.setState({
      selectedType: {
        id: event.value,
        name: event.name,
      }
    });
  }

  handleSubmit = values => {
    let formData = this.state.formData;
    for (const prop in formData) {
      if (values[prop] === undefined) {
        values[prop] = formData[prop];
      }
    }

    // Hardcode app id
    values.app_id = 1;
    values.vehicle_type_id = this.state.selectedType.id;

    values.registration_date = this.state.formData.registration_date ? moment(this.state.formData.registration_date).format('YYYY-MM-DD') : null;
    values.road_tax_expiry_date = this.state.formData.road_tax_expiry_date ? moment(this.state.formData.road_tax_expiry_date).format('YYYY-MM-DD') : null;
    values.inspection_date = this.state.formData.inspection_date ? moment(this.state.formData.inspection_date).format('YYYY-MM-DD') : null;
    // console.log("values", values);

    if (this.state.dataId === "") { // create
      this.setState({
        isLoading: true
      });

      api.create("vehicles", values)
        .then(data => {
          this.setState({
            isLoading: false,
          });

          console.log(data)

          if (data.data.status === 200) {
            this.props.history.push('../vehicles/all-vehicles');
          } else {
            apiUtil.toast(data.errorMessage, 'error');
          }
        })
        .catch(error => apiUtil.toast(error.message, 'error'));
    } else { // update
      //delete values.end_user_name;
      values.id = this.state.dataId;
      this.setState({
        isLoading: true
      });

      api.update("vehicles/" + this.state.dataId, values).then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.data.status === 200) {
          this.props.history.push('../vehicles/all-vehicles');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      });
    }
  }

  vehicleChecklistDialog = () => {
    if (this.state.dataId && this.state.dataId > 0) {
      return <Row className="pb-5 mb-5">
        <Col md={{ size: 12 }}>
          <Container>
            <VehicleChecklist vehicleId={this.state.dataId} />
          </Container>
        </Col>
      </Row>
    } else {
      return '';
    }
  }

  render() {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? "Update Vehicle" : "Create Vehicle"}</h1>
        </StickyPageHeader>

        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              plate_no: this.state.formData.plate_no,
              manufacturer: this.state.formData.manufacturer,
              model: this.state.formData.model,
              colour: this.state.formData.colour,
              load_capacity: this.state.formData.load_capacity,
              engine_capacity: this.state.formData.engine_capacity,
              registration_date: this.state.formData.registration_date,
              road_tax_expiry_date: this.state.formData.road_tax_expiry_date,
              inspection_date: this.state.formData.inspection_date,
              vehicle_type_id: this.state.formData.vehicle_type_id,
            }}
            validationSchema={VehicleSchema}
            onSubmit={values => { this.handleSubmit(values); }}>
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              validateForm
            }) => (
              <Form>
                <Col md={{ size: 12 }}>
                  <Container className={this.state.readOnly ? "overlay" : ""}>
                    <Card className="mt-3">
                      <CardBody className="pt-0">
                        <Row>
                          <Col xs="12">
                            <h4 className="font-weight-bold mb-4">Vehicle's information</h4>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Plate Number*
                            </Label>
                            <Field className="form-control form-input" name="plate_no" value={this.state.formData.plate_no || ''} onChange={this.handleInputChange} />
                            {
                              errors.plate_no && touched.plate_no ? (
                                <div className="invalid-feedback d-block">
                                  {errors.plate_no}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Manufacturer*
                            </Label>
                            <Field className="form-control form-input" name="manufacturer" value={this.state.formData.manufacturer || ''} onChange={this.handleInputChange} />
                            {
                              errors.manufacturer && touched.manufacturer ? (
                                <div className="invalid-feedback d-block">
                                  {errors.manufacturer}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Model*
                            </Label>
                            <Field className="form-control form-input" name="model" value={this.state.formData.model || ''} onChange={this.handleInputChange} />
                            {
                              errors.model && touched.model ? (
                                <div className="invalid-feedback d-block">
                                  {errors.model}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Vehicle Color*
                            </Label>
                            <Field className="form-control form-input" name="colour" value={this.state.formData.colour || ''} onChange={this.handleInputChange} />
                            {
                              errors.colour && touched.colour ? (
                                <div className="invalid-feedback d-block">
                                  {errors.colour}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Type*
                            </Label>
                            <Select value={{ label: this.state.selectedType.name, value: this.state.selectedType.id }} className="form-input no-border" options={this.state.typeList} onChange={(e) => this.handleTypeChange(e)} />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Load Capacity
                            </Label>

                            <Field className="form-control form-input" type="text" name="load_capacity" value={this.state.formData.load_capacity || ''} onChange={this.handleInputChange} />
                            {
                              errors.load_capacity && touched.load_capacity ? (
                                <div className="invalid-feedback d-block">
                                  {errors.load_capacity}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <Label className="control-label">
                              Engine Capacity
                            </Label>
                            <Field className="form-control form-input" type="text" name="engine_capacity" value={this.state.formData.engine_capacity || ''} onChange={this.handleInputChange} />
                            {
                              errors.engine_capacity && touched.engine_capacity ? (
                                <div className="invalid-feedback d-block">
                                  {errors.engine_capacity}
                                </div>
                              ) : null
                            }
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <div className="mb-4">
                              <Label className="control-label">
                                Registration Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.registration_date} onChange={date => this.changeDate(date, 'registration_date')} isClearable />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <div className="mb-4">
                              <Label className="control-label">
                                RoadTax Expiry Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.road_tax_expiry_date} onChange={date => this.changeDate(date, 'road_tax_expiry_date')} readOnly="readonly" />
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12" className="mt-3">
                            <div className="mb-4">
                              <Label className="control-label">
                                Inspection Date
                              </Label>
                              <div className="form-input">
                                <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.formData.inspection_date} onChange={date => this.changeDate(date, 'inspection_date')} readOnly="readonly" />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <div className="d-flex justify-content-between">
                      <Card>
                      </Card>
                      <ButtonGroup>
                        {(!this.state.readOnly || this.state.canSave) && <Card>
                          <ProgressButton
                            type={'submit'}
                            color={'secondary'}
                            isLoading={this.state.isLoading}
                          >
                            <Box pl={2} pr={2}>Submit</Box>
                          </ProgressButton>
                        </Card>}
                      </ButtonGroup>
                    </div>
                  </Container>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
        {this.vehicleChecklistDialog()}
      </Box>
    );
  }
}

export default VehicleForm;