import React, { Component } from "react";
import {
  Typography
} from '@material-ui/core';
import { Container, Col, Row } from "reactstrap";
import MUIDataTable from "mui-datatables";
import moment from 'moment';

class RentalReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: this.props.reportData.customer,
      total: parseFloat(this.props.reportData.grand_total_charges),
      reports: this.props.reportData.result
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reportData.customer.id !== this.state.customer.id) {
      this.setState({
        customer: nextProps.reportData.customer,
        total: parseFloat(nextProps.reportData.grand_total_charges),
        reports: nextProps.reportData.result
      });
    }
  }

  getDownloadData = async (customerId = null) => {
    await this.props.onDownload();
  }

  render() {
    const columns = [
      {
        name: "So_Ref_No",
        label: "Sales Order Reference No"
      },
      {
        name: "CR/PO/SO",
        label: "CR/PO/SO"
      },
      {
        name: "Do_Ref_No",
        label: "Delivery Order Reference No"
      },
      {
        name: "Document_No",
        label: "Document No"
      },
      {
        name: "Delivery_to",
        label: "Delivery Details"
      },
      {
        name: "Order_Type",
        label: "Order Type"
      },
      {
        name: "Division",
        label: "Division"
      },
      {
        name: "Lot_No",
        label: "Lot No"
      },
      {
        name: "Part_No",
        label: "Part No"
      },
      {
        name: "Item_Description",
        label: "Description"
      },
      {
        name: "Serial_No",
        label: "Serial No"
      },
      {
        name: "Date_In",
        label: "Date In/Out Length",
        options: {
          customBodyRender: (value) => {
            return value ? moment(value).format("DD-MM-YYYY") : '';
          }
        }
      },
      {
        name: "Length",
        label: "Length"
      },
      {
        name: "Width",
        label: "Width"
      },
      {
        name: "Height",
        label: "Height"
      },
      {
        name: "Quantity",
        label: "Quantity"
      },
      {
        name: "Cbm_Quantity",
        label: "CBM/Quantity"
      },
      {
        name: "Total_CBM",
        label: "Total CBM"
      },
      {
        name: "CBM_Balance",
        label: "CBM Balance"
      },
      // {
      //   name: "Previuos_Balance",
      //   label: "Previuos Balance"
      // },
      {
        name: "Charges",
        label: "Charges"
      },
      {
        name: "Remarks",
        label: "Remarks"
      },
      // {
      //   name: "Date_In",
      //   label: "Date"
      // },
      // {
      //   name: "Ref_NO.",
      //   label: "Ref No."
      // },
      // {
      //   name: "CR/PO/SO",
      //   label: "CR/PO/SO"
      // },
      // {
      //   name: "Item_Description",
      //   label: "Item Description"
      // },
      // {
      //   name: "Part_No",
      //   label: "Part No."
      // },
      // {
      //   name: "Qty_Bal",
      //   label: "Qty Bal."
      // },
      // {
      // 	name: "Vol_(m3)",
      // 	label: "Vol(m3)",
      //   options: {
      //     customBodyRender: (value) => {
      //       return parseFloat(value).toFixed(6).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      //     }
      //   }
      // },
      // {
      // 	name: "Amount_($)",
      // 	label: "Amount($)",
      // 	options: {
      // 		customBodyRender: (value) => {
      // 			return parseFloat(value).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      // 		}
      // 	}
      // }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: 10,
      serverSide: false,
      onDownload: (buildHead, buildBody, columns, data) => {
        this.getDownloadData();
        return false;
      },
    }

    const customer = this.state.customer;

    return (
      <Container fluid={true}>
        <Row className="mt-3">
          <Col xs="12">
            <div className="text-center mt-3 mb-3">
              <div style={{ display: "inline-block" }}>
                <h3>Grand Total</h3>
                <h2 className="font-weight-bold h2">{this.state.total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</h2>
              </div>
            </div>


            <h2 className="mb-3">Rental Fee Report</h2>

            {this.state.reports.map(function (data, index) {
              var cbmBalance = data.PreviousCbmBalance ? data.PreviousCbmBalance : 0;
              var totalCharge = data.Total ? parseFloat(data.Total).toFixed(2) : 0;

              return <Row className="mt-3">
                <Col xs="12">
                  <div style={{ display: 'block' }}>
                    <div>Company Name: <span className="font-weight-bold">{customer.company_name}</span></div>
                    <div>CBM Balance: <span className="font-weight-bold">{cbmBalance}</span></div>
                    <div>Total Charge: <span className="font-weight-bold">{totalCharge}</span></div>
                    <div>Rate: <span className="font-weight-bold">{data.Rate}</span></div>
                  </div>
                  <MUIDataTable
                    key={index}
                    className="mt-3 mb-3"
                    title={
                      <Typography variant="h6">
                        {data.Title}
                      </Typography>
                    }
                    data={data.Data}
                    columns={columns}
                    options={options}
                  />
                </Col>
              </Row>
            })}


          </Col>
        </Row>
      </Container>
    );
  }
}

export default RentalReport;