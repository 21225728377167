import React, { Component } from 'react';

import { AuthContext } from "../../components/Auth/AuthDataProvider";
import apiUtil from '../../api/apiUtil';

import {
  Button,
  Menu,
  MenuItem,
  Box,
} from '@material-ui/core';

import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import defaultImg from '../../assets/img/default_image.jpg';

class ProfileDropdown extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);

    this.state = {
      profileMenu: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profileMenu !== this.state.profileMenu) {
      this.setState({
        profileMenu: nextProps.profileMenu
      });
    }
  }

  getUserLogo = () => {
    const { user } = this.context;
    return apiUtil.getUserLogo(user, defaultImg);
  }

  getUserName = () => {
    const { user } = this.context;
    return apiUtil.getUserName(user, defaultImg);
  }

  render() {
    return (
      <Box textAlign="right" height="100%" ml={3} className="header-profile">
        <Button
          aria-label="profile-menu"
          aria-haspopup="true"
          onClick={(event) => {
            this.setState({
              profileMenu: event.currentTarget
            });
          }}>
          <img src={this.getUserLogo()} alt="User" width="35" />
          <span>{this.getUserName()}</span>
          <ArrowDownIcon />
        </Button>
        <Menu
          id="profile-menu"
          anchorEl={this.state.profileMenu}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={Boolean(this.state.profileMenu)}
          onClose={() => {
            this.setState({
              profileMenu: null
            });
          }}>
          <MenuItem
            onClick={() => {
              if (this.props.onLogout)
                this.props.onLogout();
            }}>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    );
  }
}

export default ProfileDropdown;
