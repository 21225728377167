import React, { Component } from "react";

import {
  CircularProgress,
  Typography
} from '@material-ui/core';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ButtonGroup,
  Button,
  Input,
  Col
} from "reactstrap";

import MUIDataTable from "mui-datatables";
import salesOrderApi from "../../../api/SalesOrder";
import apiUtil from "../../../api/apiUtil";
import PreviewImageDialog from "./PreviewImageDialog";

import { ReactComponent as ImageIcon } from '../../../assets/img/icons/images.svg';

import moment from "moment";

class AddItemDialog extends Component {
  constructor(props) {
    super(props);

    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);
    let items = props.items ? props.items : [];
    this.state = {
      modal: modal,
      previewImageModal: false,
      isLoading: false,
      tableState: {
        page: 1,
        rowsPerPage: 100,
        sortOrder: {}
      },
      searchTerm: '',
      items: items,
      totalItems: 0,
      selectedItems: [],
      previewImages: []
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      if (nextProps.modal) { this.searchItem(); }
      this.setState({
        modal: nextProps.modal
      });
    }

    if (nextProps.items !== this.state.items) {
      this.setState({
        items: nextProps.items
      })
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  addItem = (index) => {
    let items = this.state.items;
    if (items[index] === undefined) {
      return false;
    }
    //console.log(items[index]);
    items[index].existing = false;
    let inputQuantity = parseInt(items[index].inputQuantity);
    if (inputQuantity > 0) {
      if (inputQuantity > items[index].balance) {
        apiUtil.toast(`Quantity of item ${items[index].part_no} (${inputQuantity}) cannot be more than the balance ${items[index].balance}`, 'error');
      } else {
        console.log(items[index]);
        this.props.addItem(items[index]);
        this.searchItem();
        // delete items[index];
        //  this.setState({
        //    items: items
        //  });
      }
    }
  }

  searchItem = async () => {
    if (this.props.customer === "" || this.props.customer === "0") {
      apiUtil.toast('Select customer first.', 'error');
      return false;
    }

    /*if (this.state.searchTerm === "") {
      apiUtil.toast('Search term must be filled in', 'error');
      return false;
    }*/

    this.setState({
      isLoading: true
    });

    await salesOrderApi.getDetail({
      page: this.state.tableState.page,
      take: this.state.tableState.rowsPerPage,
      query: this.state.searchTerm,
      customer_id: this.props.customer,
      enquiry: true
    }).then(res => {
      this.setState({
        isLoading: false,
      });

      if (res.status !== 200) {
        apiUtil.toast(res.errorMessage, 'error');
        return false;
      } else {
        let items = [];
        res.data.result.forEach(item => {
          items.push(item);
        });
        this.setState({
          items: items,
          totalItems: res.data.total
        });
      }
    })
      .catch(error => apiUtil.toast(error.message, 'error'));
  }

  handleInputChange = (event, rowIndex) => {
    let items = this.state.items;
    if (items[rowIndex] === undefined) {
      return false;
    }

    items[rowIndex][event.target.name] = event.target.value;
    items[rowIndex]["inputQuantity"] = event.target.value;
    //console.log("items", items)
    this.setState({
      items: items
    });
  }

  changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.searchItem();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.searchItem();
  }

  previewImage = (index) => {
    let items = this.state.items;
    if (items[index] === undefined) {
      return false;
    }

    this.setState({
      previewImageModal: true,
      previewImages: items[index].order_images
    });
  }

  previewImageDialog = () => {
    return <PreviewImageDialog
      modal={this.state.previewImageModal}
      images={this.state.previewImages}
    />
  }

  render() {
    const columns = [
      {
        name: "container_receipt",
        label: "CR/PR/SO"
      },
      {
        name: "division",
        label: "Division"
      },
      // {
      //   name: "hawb",
      //   label: "HAWB"
      // },
      {
        name: "part_no",
        label: "Part No"
      },
      {
        name: "lot_no",
        label: "Lot No"
      },
      {
        name: "description",
        label: "Desc"
      },
      {
        name: "serial_no",
        label: "S/N No"
      },
      {
        name: "created_at",
        label: "Date In",
        options: {
          customBodyRender: (value, data) => {
            var rowData = this.state.items[data.rowIndex];
            if (rowData.order.handling_in_date) {
              let date = new Date(rowData.order.handling_in_date);
              //return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
              return moment(date).format('DD-MM-YYYY');;
            }
            return '';
          }
        }
      },
      {
        name: "volume",
        label: "Volume"
      },
      {
        name: "balance",
        label: "Balance Quantity",
        options: {
          customBodyRender: (value, data) => {
            // console.log("this.state.item", this.state.items);
            // // console.log("quantity data", data)
            // console.log("data.rowData[9",data.rowData[9])
            //console.log("- value",value)
            //console.log("Math.sign(data.rowData[9]",Math.sign(value))
            let qty = value && value != '' ? parseFloat(value) : 0;
            let inputQty = data.rowData[10] && data.rowData[10] != '' ? parseFloat(data.rowData[10]) : 0;

            // Check previously inputted
            const inputtedQty = this.props.orderDetails.filter(p => p.id == this.state.items[data.rowIndex].id && p.task == 'new');
            if (inputtedQty && inputtedQty.length > 0) {
              //console.log(inputtedQty);
              for (let i = 0; i < inputtedQty.length; i++) {
                if (inputtedQty[i].task == 'new') {
                  inputQty += inputtedQty[i].quantity;
                }
              }
            }

            let total = 0;
            if (Math.sign(value) == -1) {
              total = qty - inputQty;
            } else {
              //total = parseFloat(inputQty) - parseFloat(qty);
              total = qty - inputQty;
            }
            // const total = data.rowData[9] - qty;
            return (
              <div>
                <div>{total}</div>
              </div>
            );
          }
        }
      },
      {
        name: "location",
        label: "Location"
      },
      {
        name: "inputQuantity",
        label: "Quantity",
        options: {
          customBodyRender: (value, data) => {
            //console.log("this.state.items balance", this.state.items);
            return (
              <div>
                <Input className="form-control" name="inputQuantity" defaultValue={0} min="0" type="number" onChange={(e) => this.handleInputChange(e, data.rowIndex)} />
              </div>
            );
          }
        }
      },
      {
        name: "remarks",
        label: "Remarks",
        // options: {
        //   customBodyRender: (value, data) => {
        //     return (
        //       <div>
        //          <Input className="form-control" type="text" defaultValue={value} name="remarks" onChange={(e) => this.handleInputChange(e, data.rowIndex)} />
        //       </div>
        //     );
        //   }
        // }
      },
      {
        name: "order_images",
        label: "Images",
        options: {
          customBodyRender: (value, data) => {
            // if (value && value.length > 0) {
            //   return value.map(function (v, k) {
            //     if (!v.is_deleted) {
            //       return (<a href={v.image_url} target="_blank"><div style={{ backgroundImage: `url(${v.image_url})` }} className='col-2 img-thumbnail-container'>
            //         <img src={v.image_url} className="img-thumbnail" />
            //       </div></a>)
            //     }
            //   })
            // }

            if (value && value.length > 0) {
              return (
                <ButtonGroup>
                  <Button color="success" outline={true} onClick={() => this.previewImage(data.rowIndex)}>
                    <ImageIcon />
                  </Button>
                </ButtonGroup>
              );
            }
          }
        }
      },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRender: (value, data) => {
            return (
              <ButtonGroup>
                <Button color="success" outline={true} onClick={() => this.addItem(data.rowIndex)}>Add</Button>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      serverSide: true,
      download: false,
      search: false,
      print: false,
      count: this.state.totalItems,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page + 1, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page + 1, tableState.sortOrder);
            break;
          default:
          //console.log('action not handled.');
        }
      }
    }

    const searchInput = () => {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          this.setState({
            searchTerm: event.target.value
          }, () => {
            this.searchItem();
          });
        }
      }

      const onBlurHandler = (event) => {
        this.setState({
          searchTerm: event.target.value
        });
      }

      return <Input type="text" className="form-control" placeholder="CR/Serial No/Part No/Lot No/Location" onKeyDown={handleKeyDown} onBlur={onBlurHandler} />
    }

    return (
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="fullscreen" zIndex={1300}>
        <ModalBody>
          <Row>
            <Col xs="6">
              {searchInput()}
            </Col>

            <Col xs="6"><Button color="primary" onClick={(e) => this.searchItem(e)}>Search</Button></Col>

            <Col xs="12" className="mt-3">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    Search Item
                    {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                  </Typography>}
                data={this.state.items}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={this.handleClose}>
            Done
          </Button>
        </ModalFooter>
        {this.previewImageDialog()}
      </Modal>
    )
  }
}

export default AddItemDialog;