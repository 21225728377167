import React, { Component } from "react";
import {
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';

import MUIDataTable, { debounceSearchRender } from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';

import { Card, CardBody, Row, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import api from "../../api/api.js";

class VehicleList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableState: {
        page: 0,
        rowsPerPage: 10,
        sortOrder: {},
        search: ''
      },
      data: [],
      total: 0,
      isLoading: false
    };
  }

  componentDidMount() {
    document.title = "All Vehicles | WMS";
    window.scrollTo(0, 0);
    this.getData();
  }

  getData = async () => {
    this.setState({ isLoading: true });
    let params = {
      page: this.state.tableState.page + 1,
      take: this.state.tableState.rowsPerPage,
      app_id: 1
    };

    if (this.state.tableState.search !== '') {
      params.search = this.state.tableState.search;
    }

    api.read('vehicles', params, null, 'admin').then((results) => {
      console.log(results);
      apiUtil.parseResult(results, (data) => {
        const dataArr = (data.result && data.result.length > 0) ? data.result : [];
        this.setState({
          isLoading: false,
          data: dataArr,
          total: data.total
        });
      }, (error, type) => {
        apiUtil.toast(error.errorMessage, 'error');
        this.setState({
          isLoading: false,
        });
      });
    });
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;
    tableState.rowsPerPage = rowsPerPage;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  addNewButton = () => {
    if (apiUtil.checkAccess("VehicleCreate")) {
      return <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} />;
    }
    else {
      return '';
    }
  }

  tableActions = (access, button) => {
    if (!access) {
      return button;
    } else if (apiUtil.checkAccess(access)) {
      return button;
    }

    return '';
  }

  deleteData = id => {
    this.setState({
      modal: true,
      modalItem: id
    });
  }

  removeDialog = () => {
    return <RemoveDialog
      modal={this.state.modal}
      onRemove={(isRemove) => {
        if (isRemove) {
          let data = this.state.data.filter(r => r.id !== this.state.modalItem);

          api.delete("vehicles/" + this.state.modalItem, this.state.modalItem).then(data => {
            console.log(data.data.data);
            if (data.data.data.status === true) {
              apiUtil.toast('Successfully delete', 'success');
            }
          }).catch(err => {
            apiUtil.toast(err.message, 'error');
          });

          this.setState({
            data: data
          });
        }

        this.setState({
          modal: false,
          modalItem: null
        });
      }}
    />
  }

  render() {
    const columns = [
      {
        name: "plate_no",
        label: "Plate Number"
      },
      {
        name: "workers",
        label: "Transport Operator",
        options: {
          sort: false,
          customBodyRender: (id, data) => {
            var rowData = this.state.data[data.rowIndex];
            var operator = rowData.workers.map(x => x.first_name + " " + x.last_name).join(", ");
            return (
              <div>{operator}</div>
            );
          }
        }
      },
      {
        name: "manufacturer",
        label: "Manufacturer",
      },
      {
        name: "model",
        label: "Model"
      },
      {
        name: "vehicle_type_id",
        label: "Type",
        options: {
          customBodyRender: (val, data) => {
            var rowData = this.state.data[data.rowIndex];
            return (
              <div>{rowData.vehicle_type.name}</div>
            );
          }
        }
      },
      {
        name: "load_capacity",
        label: "Load Capicity(kg)"
      },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRender: (id, data) => {
            return <div className="btn-group d-flex align-items-center">
              {this.tableActions("VehicleView", <Tooltip title="Edit">
                <a href={'./edit?id=' + id} className=" mr-2">
                  <EditIcon className="fix-icon-color" />
                </a>
              </Tooltip>)}

              {this.tableActions("VehicleDelete", <Tooltip title="Delete">
                <button type="button" onClick={() => this.deleteData(id)} className="border-0 bg-transparent">
                  <DeleteIcon />
                </button>
              </Tooltip>)}
            </div>
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      serverSide: true,
      count: this.state.total,
      download: false,
      print: false,
      customSearchRender: debounceSearchRender(500),
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          case 'search':
            this.search(tableState.page, tableState.searchText);
            break;
          case 'changeRowsPerPage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          default:
            console.log('action not handled.');
        }
      }
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <BreadCrumb />
        <h1 className="page-title">Vehicle List</h1>
        {this.addNewButton()}
      </StickyPageHeader>

      <Card>
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    All Vehicles
                    {this.state.isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.removeDialog()}
    </Box>;
  }
}

export default VehicleList;