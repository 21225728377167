import React, { useState, useEffect, useContext } from 'react';

import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';


const tableTheme = createMuiTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#EAEDF2 !important"
      }
    },
    MUIDataTableBodyRow: {
      root: {
        '&:nth-child(even)': {
          backgroundColor: 'rgba(0,0,0,.05)'
        }
      }
    },
    MUIDataTableBodyCell: {
    }
  }
});

export default tableTheme;