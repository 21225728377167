import React, { Component } from 'react';

import { 
	Box,
	Button,
	CircularProgress
} from '@material-ui/core';

class ProgressButton extends Component {
	constructor(props){
		super(props);

		let type = props.type ? props.type : "button";
		let variant = props.variant ? props.variant : "contained";
		let color = props.color ? props.color : "default";
		let href = props.href ? props.href : null;
		let size = props.size ? props.size : "medium";
		let className = props.className ? props.className : "";
		let btnClassName = props.btnClassName ? props.btnClassName : "";
		let fullWidth = ((props.fullWidth === true || props.fullWidth === false) ? props.fullWidth : false);
		let disabled = ((props.disabled === true || props.disabled === false) ? props.disabled : false);
		let isLoading = ((props.isLoading === true || props.isLoading === false) ? props.isLoading : false);

		this.state = {
			type: type,
			variant: variant,
			color: color,
			fullWidth: fullWidth,
			href: href,
			size: size,
			disabled: disabled,
			isLoading: isLoading,
			className: className,
			btnClassName: btnClassName
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.variant !== this.state.variant) {
			this.setState({
				variant: nextProps.variant
			});
		}
		if (nextProps.color !== this.state.color) {
			this.setState({
				color: nextProps.color
			});
		}
		if (nextProps.fullWidth !== this.state.fullWidth) {
			this.setState({
				fullWidth: nextProps.fullWidth
			});
		}
		if (nextProps.href !== this.state.href) {
			this.setState({
				href: nextProps.href
			});
		}
		if (nextProps.size !== this.state.size) {
			this.setState({
				size: nextProps.size
			});
		}
		if (nextProps.disabled !== this.state.disabled) {
			this.setState({
				disabled: nextProps.disabled
			});
		}
		if (nextProps.isLoading !== this.state.isLoading) {
			this.setState({
				isLoading: nextProps.isLoading
			});
		}
	}

	render() {
		return <Box 
			className={"custom-button-component " + this.state.className}
		>
		<Button
			type={this.state.type}
			variant={this.state.variant}
			color={this.state.color}
			fullWidth={this.state.fullWidth}
			href={this.state.href}
			size={this.state.size}
			disabled={(this.state.isLoading) ? this.state.isLoading : this.state.disabled}
			className={this.state.btnClassName}
			onClick={(e) => {
				if(this.props.onClick){
					this.props.onClick(e);
				}
			}}>
		{
			(!this.state.isLoading)
			?
			this.props.children
			:
			<Box pt={1}>
				<CircularProgress size={20} />
			</Box>
		}
		</Button>
		</Box>;
	}
}

export default ProgressButton;